import React, { useState, useEffect } from "react";
import styles from "./Target.module.css";
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Button } from "../../../components/UI/Form/Button/Button";
import { AddEditForm } from "../../../components/UI/AddEditForm/AddEditForm";
import Input from "../../../components/UI/Form/Input/Input";
import {
  GET_TARGET,
  GET_AVAILABLE_SERVER,
} from "../../../graphql/queries/Target";
import { GET_TASK_DETAILS } from "../../../graphql/queries/TaskDetails";
import {
  CREATE_TARGET,
  UPDATE_TARGET,
  CREATE_TARGET_RERUN,
  DELETE_TARGET,
} from "../../../graphql/mutations/Target";
import AlertBox from "../../../components/UI/AlertBox/AlertBox";
import Loading from "../../../components/UI/Layout/Loading/Loading";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import Alert from "../../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  SUCCESS,
  UPDATE,
  DELETE,
  FAILED,
  ALERT_MESSAGE_TIMER,
} from "../../../common/MessageConstants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AutoCompleteDropDown from "../../../components/UI/Form/Autocomplete/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import { FormHelperText, makeStyles, createStyles } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import RaStepper from "../component/RaStepper/RaStepper";
import { useNavigate, useLocation } from "react-router-dom";
import { setRaStepper } from "../common/SetRaStepper";
import * as routeConstant from "../../../common/RouteConstants";
import * as msgConstant from "../../../common/MessageConstants";
import { useApolloClient } from "@apollo/client";
import stepper from "../common/raStepperList.json";
import { UPLOAD_VPN_FILE } from "../../../graphql/mutations/Upload";
import { RA_TARGET_VPNTEST } from "../../../config/index";
import { TEST_CONNECTION } from "../../../graphql/mutations/VPNConnection";
import { TEST_LINUX_CONNECTION } from "../../../graphql/mutations/VPNConnection";
import CancelIcon from "@mui/icons-material/Cancel";
import rerunstepper from "../common/raRerunStepperList.json";
import Tooltip from "@mui/material/Tooltip";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { setActiveFormStep } from "../../../services/Data";
import Cookies from "js-cookie";
import logout from "../../Auth/Logout/Logout";
import { createTheme, ThemeProvider } from "@mui/material";
import {
  DOMAIN_VERIFY,
  IP_VERIFY,
} from "../../../graphql/mutations/DomainVerify";
import {
  PG_URL,
  PG_PRICING_API_KEY,
  PG_DEDUCTION_API_KEY,
} from "../../../config/index";
import QuestionMarkIcon from "@mui/icons-material/Help";
import theme from "../../../theme";
// import { customClient } from "../../../../config/customClient";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import * as Sentry from "@sentry/react";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

export const Target: React.FC = (props: any) => {
  const { showBoundary } = useErrorBoundary();
  const history = useNavigate();
  const location = useLocation();
  const [newAdvanceTargetNameTimestamp, setNewAdvanceTargetNameTimestamp] =
    useState("");
  const [priceData, setPriceData] = useState<any>();

  const client = useApolloClient();
  const [dialogBoxMsg, setDialogBoxMsg] = useState("");
  const [openPricingBox, setOpenPricingBox] = useState<boolean>(false);
  const [showbackdrop, setShowbackdrop] = useState(true);
  // const sessionData = useQuery(RA_TARGET_SESSION);
  const targetId = localStorage.getItem("targetId")
    ? JSON.parse(localStorage.getItem("targetId") || "")
    : null;
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  //add/edit data
  const [name, setName] = useState<String>("");
  const [ipRange, setIpRange] = useState<any>("");
  const [userName, setUserName] = useState<String>("");
  const [password, setPassword] = useState<String>("");
  const [subnetMask, setSubnetMask] = useState<String>("");
  const [linuxDomain, setLinuxDomain] = useState(false);
  const [vpnUserName, setVpnUserName] = useState<String>("");
  const [vpnPassword, setVpnPassword] = useState<string>("");
  const [linuxUsername, setLinuxUsername] = useState<String>("");
  const [linuxIpAddress, setLinuxIpAddress] = useState<String>("");
  const [clientID, setClientID] = useState<String>("");
  const [winName, SetWinName] = useState<String>("");
  const [winUsername, setWinUsername] = useState<String>("");
  const [targetOldId, setTargetOldId] = useState<String>("");
  const [vpnFilePath, setVpnFilePath] = useState<any>("");
  const [displayVpnFilePath, setDisplayVpnFilePath] = useState<any>("");
  const [winIpAddress, setWinIpAddress] = useState<String>("");
  const [scanConfigList, setScanConfigList] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [linuxLoginCheckBox, setLinuxLoginCheckBox] = useState(false);
  const [windowsLoginCheckBox, setWindowsLoginCheckBox] = useState(false);
  const localVpnFilePath = JSON.parse(
    localStorage.getItem("vpnFilePath") || "{}"
  );
  const ReRunTargetName = JSON.parse(
    localStorage.getItem("re-runTargetName") || "{}"
  );
  const [fileEvent, setFileEvent] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [uploadToolOpen, setUploadToolOpen] = React.useState(false);
  const session = Cookies.getJSON("ob_session");
  const obUser = Cookies.getJSON("ob_user");
  const [param, setParam] = useState<any>(
    location?.state ? location.state[Object.keys(location.state)[0]] : null
  );
  const [connectionSuccess, SetConnectionSuccess] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  //static values for partner and client are given.
  const clientInfo = param ? param.clientInfo : undefined;
  const partner = JSON.parse(localStorage.getItem("partnerData") || "{}");
  const pg360PartnerId = JSON.parse(
    localStorage.getItem("pg360PartnerId") || "{}"
  );
  const partnerId = partner.partnerId;
  const clientId = clientInfo ? parseInt(clientInfo.clientId) : undefined;
  const targetName = param
    ? param.targetName
      ? param.targetName
      : null
    : null;
  const [editDataId, setEditDataId] = useState<Number | null>();
  if (param) {
    if (editDataId === null && param.editData === true) {
      setEditDataId(JSON.parse(localStorage.getItem("targetId") || "{}"));
    }
  }
  const TargetNameTimestamp = localStorage.getItem("TargetNameTimestamp")
    ? JSON.parse(localStorage.getItem("TargetNameTimestamp") || "")
  : null;
  //show password
  const [showPassword, setShowPassword] = useState(false);
  const [showVpnPassword, setShowVpnPassword] = useState(false);
  const startDate = new Date();
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [certCheckBox, setCertCheckBox] = useState(false);
  const [certToolTip,setCertToolTip] = useState(false);
  const [targetOpen, setTargetOpen] = React.useState(false);
  const customClientUrl = localStorage.getItem("customClientUrl") || "";
  //validation and error handelling
  const [isError, setIsError] = useState<any>({
    name: "",
    ipRange: "",
    userName: "",
    password: "",
    vpnUserName: "",
    vpnPassword: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });

  //queries

  const [testVpnConnection] = useMutation(TEST_CONNECTION);
  const [testLinuxConnection] = useMutation(TEST_LINUX_CONNECTION);

  const [domainVerify] = useMutation(DOMAIN_VERIFY);
  const [IPVerify] = useMutation(IP_VERIFY);

  // Set props data as Param
  useEffect(() => {
    if (location?.state) {
      setParam(location.state[Object.keys(location.state)[0]]);
    }
    getPricingData();
  }, []);

  useEffect(() =>{
    if(location?.state?.data?.checkboxSelected){
      setCertCheckBox(location.state.data.checkboxSelected)
      setLinuxLoginCheckBox(location?.state?.data?.linuxLoginSelected);
      setWindowsLoginCheckBox(location?.state?.data?.windowLoginSelected);
    }
  },[])
  useEffect(() =>{
    setName(param.scanName);
    setIpRange(param.targetIpRange);
    setSubnetMask(param.subnetMask);
  },[param])

  const getPricingData = async () => {
    let headerObj = {
      "api-key": PG_PRICING_API_KEY,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    try {
      // partner id should be dynamic
      await fetch(PG_URL + "ob360-scans/api/v1/check-scan-count/post", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify({
          partner_id: pg360PartnerId,
          type: "OB360",
          sub_type: "VA",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setPriceData(data);
        });
    } catch (err:any) {
      Sentry.captureException(err);
    }
  };

  const [updateTarget] = useMutation(UPDATE_TARGET);
  const [deleteTarget] = useMutation(DELETE_TARGET);

  const [uploadFile] = useMutation(UPLOAD_VPN_FILE);

  const [createTarget] = useMutation(CREATE_TARGET);
  const [createReRunTarget] = useMutation(CREATE_TARGET_RERUN);
  const [
    getTargetData,
    { data: targetData, loading: targetLoading, error: targetError },
  ] = useLazyQuery(GET_TARGET, {
    onCompleted: (data: any) => {
      setActiveFormStep(0);
      if (data.getCredentialsDetails.edges[0]) {
        setIpRange(data.getCredentialsDetails.edges[0].node.vatTarget.host);
        setVpnUserName(
          data.getCredentialsDetails.edges[0].node
            ? data.getCredentialsDetails.edges[0].node.vpnUsername
            : null
        );
        setLinuxUsername(
          data.getCredentialsDetails.edges[0].node &&
            data.getCredentialsDetails.edges[0].node.domainUsername
            ? data.getCredentialsDetails.edges[0].node.domainUsername
            : null
        );
        setLinuxIpAddress(
          data.getCredentialsDetails.edges[0].node &&
            data.getCredentialsDetails.edges[0].node.linuxIpAddress
            ? data.getCredentialsDetails.edges[0].node.linuxIpAddress
            : null
        );
        SetWinName(
          data.getCredentialsDetails.edges[0].node &&
            data.getCredentialsDetails.edges[0].node.winName
            ? data.getCredentialsDetails.edges[0].node.winName
            : null
        );
        setWinUsername(
          data.getCredentialsDetails.edges[0].node &&
            data.getCredentialsDetails.edges[0].node.winUsername
            ? data.getCredentialsDetails.edges[0].node.winUsername
            : null
        );
        setWinIpAddress(
          data.getCredentialsDetails.edges[0].node
            ? data.getCredentialsDetails.edges[0].node.winIpAddress
            : null
        );
        setClientID(
          data.getCredentialsDetails.edges[0].node
            ? data.getCredentialsDetails.edges[0].node.client.id
            : null
        );
        setTargetOldId(
          data.getCredentialsDetails.edges[0].node
            ? data.getCredentialsDetails.edges[0].node.vatTarget.id
            : null
        );
        if (
          data.getCredentialsDetails.edges[0].node &&
          data.getCredentialsDetails.edges[0].node.vatTarget.vpnFilePath
        )
          setVpnFilePath(
            data.getCredentialsDetails.edges[0].node
              ? data.getCredentialsDetails.edges[0].node.vatTarget.vpnFilePath
              : null
          );
        setDisplayVpnFilePath(
          data.getCredentialsDetails.edges[0].node
            ? data.getCredentialsDetails.edges[0].node.vatTarget.vpnFilePath.split(
                "/"
              )[9]
            : null
        );
      }
    },
    onError: (err:any) => {
      sentry_error_catch(err,setBackdrop,setFormState)
    },
    fetchPolicy: "cache-and-network",
  });
  console.log("param",param);
  const [getTaskData, { data: taskData, loading: taskLoading }] = useLazyQuery(
    GET_TASK_DETAILS,
    {
      onCompleted: (data: any) => {
        if (data.getTask.edges) {
          setScanConfigList(data.getTask.edges[0].node.vatScanConfigList);
        }
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const handleToolTipClose = () => {
    setOpen(false);
  };

  const handleToolTipOpen = () => {
    setOpen(true);
  };
  const handleUploadToolTipClose = () => {
    setUploadToolOpen(false);
  };

  const handleUploadToolTipOpen = () => {
    setUploadToolOpen(true);
  };

  const checkValidation = () => {
    if (
      isError.name !== "" ||
      isError.ipRange !== "" ||
      isError.userName !== "" ||
      isError.password !== "" ||
      isError.vpnUserName !== "" ||
      isError.vpnPassword !== "" ||
      !name ||
      !ipRange ||
      !userName ||
      !password ||
      !vpnUserName ||
      !vpnPassword
    ) {
      return true;
    }
    return false;
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  useEffect(() => {
    if (Cookies.getJSON("ob_session")) {
      if (targetName !== null) {
        getTargetData({
          variables: {
            vat_target_id: param.targetData.targetId,
          },
        });
      }
      if (param != undefined && param.editData && param.editData === true) {
        const localIpRange = localStorage.getItem("ipRange") ? JSON.parse(localStorage.getItem("ipRange")|| "") : null;
        const localvpnUsername = localStorage.getItem("vpnUserName") ? JSON.parse(localStorage.getItem("vpnUserName") || ""):null ;
        const localvpnPassword = localStorage.getItem("vpnPassword") ? JSON.parse(localStorage.getItem("vpnPassword") || ""):null;
        const localSubnetMask = localStorage.getItem("subnetMask") ? JSON.parse(localStorage.getItem("subnetMask") || ""):null;
        if(param && param.targetIpRange && vpnUserName && vpnPassword && param.subnetMask && param.targetIpRange === localIpRange && vpnUserName === localvpnUsername 
          && vpnPassword === localvpnPassword && param.subnetMask === localSubnetMask){
          setSubmitDisabled(false);
          console.log('inside edit ture if')
          setFileUploaded(true);
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: "Connection has been validated",
          }));
        }else{
          setSubmitDisabled(true);
        }
        
        setVpnUserName(JSON.parse(localStorage.getItem("vpnUserName") || "{}"));
        setVpnPassword(JSON.parse(localStorage.getItem("vpnPassword") || "{}"));
        setVpnFilePath(
          Object.keys(localVpnFilePath).length > 0 || vpnFilePath
            ? localVpnFilePath.replace(/\"/g, "")
            : null
        );
        setDisplayVpnFilePath(
          Object.keys(localVpnFilePath).length > 0 || vpnFilePath
            ? localVpnFilePath.split("/")[9].replace(/\"/g, "")
            : null
        );
        if(!(targetId && targetId.length > 0)){
          if((param.targetIpRange !== localStorage.getItem("initialTargetIpRange")) 
            || (param.subnetMask !== localStorage.getItem("initialSubnetMask"))
          ){
            console.log('changed')
            setSubmitDisabled(true);
            setSelectedFile(param.vpnfile ? param.vpnfile : null)
          }else{
            console.log('not changed')
            setSubmitDisabled(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: true,
              isUpdate: false,
              isDelete: false,
              isFailed: false,
              errMessage: "Connection has been validated",
            }));
            setSelectedFile(param.vpnfile ? param.vpnfile : null)
          }
        }
      }
      localStorage.setItem("initialTargetIpRange",param.targetIpRange)
      localStorage.setItem("initialSubnetMask",param.subnetMask)
    } else {
      logout();
    }
  }, []);

  if (targetId && editDataId === undefined) {
    if (targetId.length > 0) {
      setEditDataId(JSON.parse(localStorage.getItem("targetId") || "{}"));
      setName(JSON.parse(localStorage.getItem("name") || "{}"));
      setIpRange(JSON.parse(localStorage.getItem("ipRange") || "{}"));
      setUserName(JSON.parse(localStorage.getItem("userName") || "{}"));
      setVpnUserName(JSON.parse(localStorage.getItem("vpnUserName") || "{}"));
      setVpnPassword(JSON.parse(localStorage.getItem("vpnPassword") || "{}"));
    }
  }

  useEffect(() => {
    try {
      if (param.reRun && param.targetName.includes("_windows")) {
        setRaStepper(
          client,
          rerunstepper.VPNConnect.name,
          rerunstepper.VPNConnect.value,
          param
        );
      } else if (ReRunTargetName && ReRunTargetName.includes("_windows")) {
        let data = {
          LinuxNetwork:
            param && param.LinuxNetwork ? param.LinuxNetwork : false,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : true,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo ? param.clientInfo : null,
          targetInfo: param && param.targetInfo ? param.targetInfo : null,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          targetName: ReRunTargetName ? ReRunTargetName : targetName,
        };
        setRaStepper(
          client,
          rerunstepper.VPNConnect.name,
          rerunstepper.VPNConnect.value,
          data
        );
      } else {
        setActiveFormStep(1);
        setRaStepper(client, stepper.VPNConnect.name, stepper.VPNConnect.value, param);
      }
    } catch(err:any) {
      if (param.reRun && param.targetName.includes("_windows")) {
        setRaStepper(
          client,
          rerunstepper.VPNConnect.name,
          rerunstepper.VPNConnect.value,
          param
        );
      } else {
        setActiveFormStep(1);
        setRaStepper(client, stepper.VPNConnect.name, stepper.VPNConnect.value, param);
      }
    }
  }, []);

  if (targetError) {
    return <div className="error">Error!</div>;
  }

  const handleSubmitDialogBox = () => {
    try{
    handleAlertClose();
    if (!Cookies.getJSON("ob_session")) {
      logout();
    }
    if (editDataId) {
      let localIpRange = localStorage.getItem("ipRange") ? JSON.parse(localStorage.getItem("ipRange")|| "") : null;
      const localvpnUsername = localStorage.getItem("vpnUserName") ? JSON.parse(localStorage.getItem("vpnUserName") || ""):null ;
      const localvpnPassword = localStorage.getItem("vpnPassword") ? JSON.parse(localStorage.getItem("vpnPassword") || ""):null;
      const localSubnetMask = localStorage.getItem("subnetMask") ? JSON.parse(localStorage.getItem("subnetMask") || ""):null;
      if(param.targetIpRange && vpnUserName && vpnPassword && param.subnetMask && param.targetIpRange === localIpRange && vpnUserName === localvpnUsername 
        && vpnPassword === localvpnPassword && param.subnetMask === localSubnetMask){
        setTimeout(() => {
          setLinuxDomain(true);
          setShowDialogBox(true);
          setDialogBoxMsg(msgConstant.LOGIN_CREDENTIALS);
        }, 1000);
      }else{
      setSubmitDisabled(true);
      setBackdrop(true);
      let input = {
        partner: partnerId.id,
        client: clientId,
        targetName: TargetNameTimestamp,
        host: ipRange,
        authCheckboxSelected : certCheckBox? "Yes":"No",
        vpnUsername: vpnUserName,
        vpnPassword: vpnPassword,
        subnet:subnetMask,
        user: parseInt(partner.userId["id"]),
      };
      let id = editDataId;
      updateTarget({
        variables: {
          input,
          id,
        },
      })
        .then((userRes) => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: true,
            isDelete: false,
            isFailed: false,
            errMessage: msgConstant.TARGET_UPDATE_SUCCESS,
          }));
          setBackdrop(false);
          setSubmitDisabled(false);
          setUploadDisabled(true);
          setFileUploaded(false);
          setEditDataId(null);
          let substring = "_linux";
          let substring2 = "_windows";
          let NormalTargetName =
            userRes.data.updateTarget.targetField.targetName;
          let TargetNames = "";
          if (NormalTargetName.includes(substring)) {
            TargetNames = NormalTargetName.replace(substring, "");
          } else if (NormalTargetName.includes(substring2)) {
            TargetNames = NormalTargetName.replace(substring2, "");
          } else {
            TargetNames = userRes.data.updateTarget.targetField.targetName;
          }
          localStorage.setItem("name", JSON.stringify(TargetNames));
          localStorage.setItem(
            "targetId",
            JSON.stringify(userRes.data.updateTarget.targetField.id)
          );
          localStorage.setItem(
            "updatetargettimestamp",
            JSON.stringify(userRes.data.updateTarget.targetField.targetName)
          );
          localStorage.setItem("ipRange", JSON.stringify(ipRange));
          localStorage.setItem("vpnUserName", JSON.stringify(vpnUserName));
          localStorage.setItem("vpnPassword", JSON.stringify(vpnPassword));
          try {
            // Rerun Of Windows Navigate
            if (ReRunTargetName && ReRunTargetName.includes("_windows")) {
              data = {
                LinuxNetwork:
                  param && param.LinuxNetwork ? param.LinuxNetwork : true,
                windowsNetwork:
                  param && param.windowsNetwork ? param.windowsNetwork : false,
                editData: param && param.editData ? param.editData : false,
                clientInfo: param && param.clientInfo,
                targetInfo: targetInfo,
                editLinuxData: param.editLinuxData
                  ? param.editLinuxData
                  : false,
                editWindowsData: param.editWindowsData
                  ? param.editWindowsData
                  : false,
                checkboxSelected:certCheckBox,
                linuxLoginSelected: linuxLoginCheckBox,
                windowLoginSelected: windowsLoginCheckBox,
                scanName : param.scanName ?  param.scanName : '',
                targetIpRange : param.targetIpRange ? param.targetIpRange : '',
                subnetMask : param.subnetMask ? param.subnetMask : '',
              };
              history(routeConstant.LOGIN_CREDENTIALS, { state: { data } });
            }
            // Rerun Of Linux Navigate
            else {
              data = {
                LinuxNetwork:
                  param && param.LinuxNetwork ? param.LinuxNetwork : true,
                windowsNetwork:
                  param && param.windowsNetwork ? param.windowsNetwork : false,
                editData: param && param.editData ? param.editData : false,
                clientInfo: param && param.clientInfo,
                targetInfo: targetInfo,
                editLinuxData: param.editLinuxData
                  ? param.editLinuxData
                  : false,
                editWindowsData: param.editWindowsData
                  ? param.editWindowsData
                  : false,
                scanName : param.scanName ?  param.scanName : '',
                targetIpRange : param.targetIpRange ? param.targetIpRange : '',
                subnetMask : param.subnetMask ? param.subnetMask : '',
                checkboxSelected:certCheckBox,
                linuxLoginSelected: linuxLoginCheckBox,
                windowLoginSelected: windowsLoginCheckBox,
              };
              history(routeConstant.LOGIN_CREDENTIALS, { state: { data } });
            }
          } catch {
            setShowDialogBox(false);
            setLinuxDomain(true);
            setShowDialogBox(true);
            setBackdrop(false);
            setDialogBoxMsg(msgConstant.LOGIN_CREDENTIALS);
          }
        })
        .catch((err) => {
          setShowDialogBox(false);
          setSubmitDisabled(true);
          setBackdrop(false);

          let error = err.message;
          if (
            error.includes("duplicate key value violates unique constraint")
          ) {
            error = " Name already present.";
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: error,
            }));
          }
          if (error.includes("Response Error 400. Target exists already")) {
            error = " Scan Name already present.";
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: error,
            }));
          } else {
            sentry_error_catch(err,setBackdrop,setFormState)
          }
        });
      }
    } else {
      setSubmitDisabled(true);
      setBackdrop(true);
      if (
        partnerId &&
        clientId &&
        name &&
        ipRange &&
        (vpnUserName || certCheckBox) &&
        param &&
        param.reRun == true
      ) {
        if (linuxUsername != null || winIpAddress != null) {
          let input = {
            partner: partnerId.id,
            client: clientId,
            targetName: name,
            host:
              ipRange != null
                ? ipRange
                : JSON.parse(localStorage.getItem("ipRange") || "{}"),
            vpnUsername:
              vpnUserName != null
                ? vpnUserName
                : JSON.parse(localStorage.getItem("vpnUserName") || "{}"),
            vpnPassword: vpnPassword ? vpnPassword : null,
            linuxUsername: linuxUsername ? linuxUsername : null,
            linuxIpAddress: linuxIpAddress ? linuxIpAddress : null,
            subnet:subnetMask ? subnetMask :null,
            startDate: startDate,
            targetOldId: targetOldId,
            winName: winName ? winName : null,
            winIpAddress: winIpAddress ? winIpAddress : null,
            winUsername: winUsername ? winUsername : null,
          };

          createReRunTarget({
            variables: {
              input,
            },
          })
            .then((userRes) => {
              setSubmitDisabled(false);
              setBackdrop(false);
              setFormState((formState) => ({
                ...formState,
                isSuccess: true,
                isUpdate: false,
                isDelete: false,
                isFailed: false,
                errMessage: msgConstant.SCAN_CREATED_SUCCESS,
              }));
              localStorage.setItem("name", JSON.stringify(name));
              localStorage.setItem(
                "targetId",
                JSON.stringify(userRes.data.createTargetRerun.targetField.id)
              );
              localStorage.setItem(
                "vpnFilePath",
                JSON.stringify(
                  userRes.data.createTargetRerun.targetField.vpnFilePath
                )
              );
              if (param && param.reRun == true) {
                localStorage.setItem(
                  "re-runTargetName",
                  JSON.stringify(param.targetName)
                );
              }
              localStorage.setItem("ipRange", JSON.stringify(ipRange));
              localStorage.setItem("vpnUserName", JSON.stringify(vpnUserName));
              localStorage.setItem("vpnPassword", JSON.stringify(vpnPassword));
              setShowDialogBox(false);
              setBackdrop(false);
              let data = {};
              let targetInfo = {
                targetName: name,
                host: ipRange,
                subnetMask: subnetMask,
                userName: userName,
                password: password,
              };
              // Rerun Of Windows Navigate
              if (winIpAddress != null) {
                data = {
                  LinuxNetwork:
                    param && param.LinuxNetwork ? param.LinuxNetwork : true,
                  windowsNetwork:
                    param && param.windowsNetwork
                      ? param.windowsNetwork
                      : false,
                  editData: param && param.editData ? param.editData : false,
                  clientInfo: param && param.clientInfo,
                  targetInfo: targetInfo,
                  editLinuxData: param.editLinuxData
                    ? param.editLinuxData
                    : false,
                  editWindowsData: param.editWindowsData
                    ? param.editWindowsData
                    : false,
                  checkboxSelected:certCheckBox,
                  linuxLoginSelected: linuxLoginCheckBox,
                  windowLoginSelected: windowsLoginCheckBox,
                  scanName : param.scanName ?  param.scanName : '',
                  targetIpRange : param.targetIpRange ? param.targetIpRange : '',
                  subnetMask : param.subnetMask ? param.subnetMask : '',
                };
                history(routeConstant.LOGIN_CREDENTIALS, { state: { data } });
              }
              // Rerun Of Linux Navigate
              if (linuxUsername != null) {
                data = {
                  LinuxNetwork:
                    param && param.LinuxNetwork ? param.LinuxNetwork : true,
                  windowsNetwork:
                    param && param.windowsNetwork
                      ? param.windowsNetwork
                      : false,
                  editData: param && param.editData ? param.editData : false,
                  clientInfo: param && param.clientInfo,
                  targetInfo: targetInfo,
                  editLinuxData: param.editLinuxData
                    ? param.editLinuxData
                    : false,
                  editWindowsData: param.editWindowsData
                    ? param.editWindowsData
                    : false,
                  checkboxSelected:certCheckBox,
                  linuxLoginSelected: linuxLoginCheckBox,
                  windowLoginSelected: windowsLoginCheckBox,
                  scanName : param.scanName ?  param.scanName : '',
                  targetIpRange : param.targetIpRange ? param.targetIpRange : '',
                  subnetMask : param.subnetMask ? param.subnetMask : '',
                };
                history(routeConstant.LOGIN_CREDENTIALS, { state: { data } });
              }
            })
            .catch((err:any) => {
              setShowDialogBox(false);
              setSubmitDisabled(true);
              setBackdrop(false);
              let error = err.message;
              if (
                error.includes("duplicate key value violates unique constraint")
              ) {
                error = " Name already present.";
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: error,
                }));
              }
              if (error.includes("Response Error 400. Target exists already")) {
                error = " Scan Name already present.";
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: error,
                }));
              } else {
                sentry_error_catch(err,setBackdrop,setFormState)
              }
              
            });
        }
      } else {
        if (partnerId && clientId && name && ipRange && (vpnUserName || certCheckBox)) {
          setBackdrop(true);
          let input = {
            partner: partnerId.id,
            client: clientId,
            targetName: name,
            host: ipRange,
            vpnUsername: vpnUserName,
            vpnPassword: vpnPassword,
            authCheckboxSelected : certCheckBox? "Yes":"No",
            startDate: startDate,
            scanType: "Advanced",
            subnet:subnetMask,
            user: parseInt(partner.userId["id"]),
          };
          createTarget({
            variables: {
              input,
            },
          })
            .then((userRes) => {
              setBackdrop(false);
              if (userRes.data.createTarget.status === "Duplicate") {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: msgConstant.SCAN_NAME_EXITS_ERROR,
                }));
              } else if (userRes.data.createTarget.status === "Success") {
                setNewAdvanceTargetNameTimestamp(
                  userRes.data.createTarget.targetField.targetName
                );
                localStorage.setItem(
                  "TargetNameTimestamp",
                  JSON.stringify(
                    userRes.data.createTarget.targetField.targetName
                  )
                );
                setSubmitDisabled(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: true,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: false,
                  errMessage: msgConstant.SCAN_CREATED_SUCCESS,
                }));
                localStorage.setItem("name", JSON.stringify(name));
                localStorage.setItem(
                  "targetId",
                  JSON.stringify(userRes.data.createTarget.targetField.id)
                );
                localStorage.setItem(
                  "vpnFilePath",
                  JSON.stringify(
                    userRes.data.createTarget.targetField.vpnFilePath
                  )
                );
                if (param && param.reRun == true) {
                  localStorage.setItem(
                    "re-runTargetName",
                    JSON.stringify(param.targetName)
                  );
                }
                localStorage.setItem("ipRange", JSON.stringify(ipRange));
                localStorage.setItem(
                  "vpnUserName",
                  JSON.stringify(vpnUserName)
                );
                localStorage.setItem(
                  "vpnPassword",
                  JSON.stringify(vpnPassword)
                );
                localStorage.setItem(
                  "subnetMask",
                  JSON.stringify(subnetMask)
                );
                setShowDialogBox(false);
                let data = {};
                let targetInfo = {
                  targetName: name,
                  host: ipRange,
                  userName: userName,
                  subnetMask: subnetMask,
                  password: password,
                };
                setTimeout(() => {
                  setLinuxDomain(true);
                  setShowDialogBox(true);
                  setDialogBoxMsg(msgConstant.LOGIN_CREDENTIALS);
                }, 1000);
              } else {
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: msgConstant.API_FAILED_MSG,
                }));
              }
            })
            .catch((err) => {
              setSubmitDisabled(false);
              setBackdrop(false);
              let error = err.message;
              if (
                error.includes("duplicate key value violates unique constraint")
              ) {
                error = " Name already present.";
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: error,
                }));
              }
              if (error.includes("Response Error 400. Target exists already")) {
                error = " Scan Name already present.";
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: false,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: true,
                  errMessage: error,
                }));
              } else {
                sentry_error_catch(err,setBackdrop,setFormState)
              }
            });
        }
      }
    }}catch(error){
      showBoundary(error)
    }
  }; 

  const onChangeHandler = (event: any, removeCase: any) => {
    try{
    if (event && event.target !== undefined) {
      setSelectedFile(event.target.files[0]);
      setFileEvent(event);
      if (event.target.files[0]) {
        setUploadDisabled(false);
      } else {
        setUploadDisabled(true);
      }
    }
    if (event && removeCase === "remove") {
      event.target.value = null;
      setSelectedFile(null);
    }}catch(error){
      showBoundary(error)
    }
  };

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (err:any) {
      sentry_error_catch(err,setBackdrop,setFormState)
    };
  };

  const onClickHandler2 = () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      if (handleInputErrors()) {
        if (/[^a-zA-Z0-9\- \/]/.test(name.toString())) {
          setBackdrop(false);
        } else {
          // Check Domain Connectipn
          let input = {
            host: ipRange,
            scanType: "Advanced",
          };
          if (parseInt(ipRange)) {
            IPVerify({
              variables: {
                input,
              },
            })
              .then((userRes) => {
                if (userRes.data.IPVerify.status === "Valid IP address") {
                  submitAction();
                } else {
                  setBackdrop(false);
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: false,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: true,
                    errMessage: " Please Enter Valid IP Address",
                  }));
                }
              })
              .catch((err) => {
                sentry_error_catch(err,setBackdrop,setFormState)
              });
          } else {
            domainVerify({
              variables: {
                input,
              },
            })
              .then((userRes) => {
                if (
                  userRes.data.domainVerify.status ===
                  "Domain name is registered"
                ) {
                  submitAction();
                } else {
                  setBackdrop(false);
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: false,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: true,
                    errMessage: " Please Enter Valid Domain Name",
                  }));
                }
              })
              .catch((err) => {
                setUploadDisabled(true);
                setFileUploaded(false);
                setSelectedFile(null);
                sentry_error_catch(err,setBackdrop,setFormState)
              });
          }
        }
      } else {
        if (!handleInputErrors()) {
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: " Please fill in the required fields",
          }));
        }
      }
    } else {
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };
  const submitAction = () => {
    try{
    handleAlertClose();
    setBackdrop(true);
    if (selectedFile && selectedFile.name != null) {
      let idCardBase64 = "";
      getBase64(selectedFile, (result: any) => {
        idCardBase64 = result;
        var res = result.slice(result.indexOf(",") + 1);
        if (targetData !== undefined) {
          if (param && param.reRun === true) {
            if (
              selectedFile.name.substring(
                selectedFile.name.lastIndexOf(".") + 1,
                selectedFile.name.length
              ) === "ovpn" ||
              selectedFile.name.substring(
                selectedFile.name.lastIndexOf(".") + 1,
                selectedFile.name.length
              ) === "tgz"
            ) {
              uploadFile({
                variables: {
                  input: {
                    client: param.clientInfo.clientId,
                    targetName: name,
                    file: res,
                    vpnUsername: vpnUserName,
                    vpnPassword: vpnPassword,
                    type: selectedFile.name.substring(
                      selectedFile.name.lastIndexOf(".") + 1,
                      selectedFile.name.length
                    ),
                    authCheckboxSelected : certCheckBox? "Yes":"No",
                    targetId:
                      targetData.getCredentialsDetails.edges[0].node.vatTarget
                        .id,
                  },
                },
              })
                .then((response: any) => {
                  setBackdrop(false);
                  if (
                    response.data.uploadFile.success == "File Uploaded Failed"
                  ) {
                    setFileUploaded(false);
                    setFormState((formState) => ({
                      ...formState,
                      isSuccess: false,
                      isUpdate: false,
                      isDelete: false,
                      isFailed: true,
                      errMessage: msgConstant.VPN_FILE_UPLOAD_FAILED,
                    }));
                  }else if(response.data.uploadFile.success == msgConstant.VPN_BACKEND_RESPONSE){
                    setFileUploaded(false);
                    setFormState((formState) => ({
                      ...formState,
                      isSuccess: false,
                      isUpdate: false,
                      isDelete: false,
                      isFailed: true,
                      errMessage: msgConstant.VPN_VALIDATION,
                    }));
                  } else {
                    setFileUploaded(true);
                    onClickTestConnection();
                  }
                })
                .catch((err: Error) => {
                  setUploadDisabled(true);
                  setFileUploaded(false);
                  setSelectedFile(null);
                  sentry_error_catch(err,setBackdrop,setFormState)
                });
            } else {
              setFileUploaded(false);
              setBackdrop(false);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_FILE_INVALID_FORMAT,
              }));
            }
          }
        } else {
          if (
            selectedFile.name.substring(
              selectedFile.name.lastIndexOf(".") + 1,
              selectedFile.name.length
            ) === "ovpn" ||
            selectedFile.name.substring(
              selectedFile.name.lastIndexOf(".") + 1,
              selectedFile.name.length
            ) === "tgz"
          ) {
            uploadFile({
              variables: {
                input: {
                  client: param.clientInfo.clientId,
                  targetName: name,
                  file: res,
                  vpnUsername: vpnUserName,
                  vpnPassword: vpnPassword,
                  type: selectedFile.name.substring(
                    selectedFile.name.lastIndexOf(".") + 1,
                    selectedFile.name.length
                  ),
                },
              },
            })
              .then((response: any) => {
                setBackdrop(false);
                if (
                  response.data.uploadFile.success == "File Uploaded Failed"
                ) {
                  setFileUploaded(false);
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: false,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: true,
                    errMessage: msgConstant.VPN_FILE_UPLOAD_FAILED,
                  }));
                }else if(response.data.uploadFile.success == msgConstant.VPN_BACKEND_RESPONSE){
                  setFileUploaded(false);
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: false,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: true,
                    errMessage: msgConstant.VPN_VALIDATION,
                  }));
                } else {
                  setFileUploaded(true);

                  onClickTestConnection();
                }
              })
              .catch((err: Error) => {
                setUploadDisabled(true);
                setFileUploaded(false);
                setSelectedFile(null);
                sentry_error_catch(err,setBackdrop,setFormState)
              });
          } else {
            setFileUploaded(false);
            setBackdrop(false);
            setFormState((formState) => ({
              ...formState,
              isSuccess: false,
              isUpdate: false,
              isDelete: false,
              isFailed: true,
              errMessage: msgConstant.VPN_FILE_INVALID_FORMAT,
            }));
          }
        }
      });
    } else {
      setBackdrop(false);
      setSelectedFile(null);
      if (vpnFilePath) {
        uploadFile({
          variables: {
            input: {
              client: param.clientInfo.clientId,
              targetName: name,
              vpnUsername: vpnUserName,
              vpnPassword: vpnPassword,
              targetId:
                targetData != undefined
                  ? targetData.getCredentialsDetails.edges[0].node.vatTarget.id
                  : targetId,
              filePath: vpnFilePath,
            },
          },
        })
          .then((response: any) => {
            setBackdrop(false);
            if (response.data.uploadFile.success == "File Uploaded Failed") {
              setFileUploaded(false);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_FILE_UPLOAD_FAILED,
              }));
            } else if(response.data.uploadFile.success == msgConstant.VPN_BACKEND_RESPONSE){
              setFileUploaded(false);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_VALIDATION,
              }));
            } else {
              setFileUploaded(true);
              onClickTestConnection();
            }
          })
          .catch((err: Error) => {
            setUploadDisabled(true);
            setFileUploaded(false);
            setSelectedFile(null);
            sentry_error_catch(err,setBackdrop,setFormState)
          });
      } else {
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: msgConstant.SELECT_VPN_FILE,
        }));
      }
    }}catch(error){
      showBoundary(error)
    }
  };
  const onClickHandler = (event: any) => {
    if (name && vpnUserName && !uploadDisabled) {
      setBackdrop(true);
      if (selectedFile && selectedFile.name != null) {
        let idCardBase64 = "";
        getBase64(selectedFile, (result: any) => {
          idCardBase64 = result;
          var res = result.slice(result.indexOf(",") + 1);
          if (targetData !== undefined) {
            if (param && param.reRun === true) {
              uploadFile({
                variables: {
                  input: {
                    client: param.clientInfo.clientId,
                    targetName: name,
                    file: res,
                    vpnUsername: vpnUserName,
                    vpnPassword: vpnPassword,
                    type: selectedFile.name.substring(
                      selectedFile.name.lastIndexOf(".") + 1,
                      selectedFile.name.length
                    ),
                    targetId:
                      targetData.getCredentialsDetails.edges[0].node.vatTarget
                        .id,
                  },
                },
              })
                .then((response: any) => {
                  setBackdrop(false);
                  if (
                    response.data.uploadFile.success == "File Uploaded Failed"
                  ) {
                    setFileUploaded(false);
                    setFormState((formState) => ({
                      ...formState,
                      isSuccess: false,
                      isUpdate: false,
                      isDelete: false,
                      isFailed: true,
                      errMessage: msgConstant.VPN_FILE_UPLOAD_FAILED,
                    }));
                  } else if(response.data.uploadFile.success == msgConstant.VPN_BACKEND_RESPONSE){
                    setFileUploaded(false);
                    setFormState((formState) => ({
                      ...formState,
                      isSuccess: false,
                      isUpdate: false,
                      isDelete: false,
                      isFailed: true,
                      errMessage: msgConstant.VPN_VALIDATION,
                    }));
                  } else {
                    setFileUploaded(true);
                  }
                })
                .catch((err: Error) => {
                  setUploadDisabled(true);
                  setFileUploaded(false);
                  setSelectedFile(null);
                  sentry_error_catch(err,setBackdrop,setFormState)
                });
            }
          } else {
            uploadFile({
              variables: {
                input: {
                  client: param.clientInfo.clientId,
                  targetName: name,
                  file: res,
                  vpnUsername: vpnUserName,
                  vpnPassword: vpnPassword,
                  type: selectedFile.name.substring(
                    selectedFile.name.lastIndexOf(".") + 1,
                    selectedFile.name.length
                  ),
                },
              },
            })
              .then((response: any) => {
                setBackdrop(false);
                if (
                  response.data.uploadFile.success == "File Uploaded Failed"
                ) {
                  setFileUploaded(false);
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: false,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: true,
                    errMessage: msgConstant.VPN_FILE_UPLOAD_FAILED,
                  }));
                } else if(response.data.uploadFile.success == msgConstant.VPN_BACKEND_RESPONSE){
                  setFileUploaded(false);
                  setFormState((formState) => ({
                    ...formState,
                    isSuccess: false,
                    isUpdate: false,
                    isDelete: false,
                    isFailed: true,
                    errMessage: msgConstant.VPN_VALIDATION,
                  }));
                } else {
                  setFileUploaded(true);
                }
              })
              .catch((err: Error) => {
                setUploadDisabled(true);
                setFileUploaded(false);
                setSelectedFile(null);
                sentry_error_catch(err,setBackdrop,setFormState)
              });
          }
        });
      } else {
        setBackdrop(false);
        setSelectedFile(null);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: msgConstant.SELECT_VPN_FILE,
        }));
      }
    } else {
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: true,
        errMessage: "Please fill in the required fields and upload the file ",
      }));
    }
  };
  let obuserData = JSON.parse(obUser);
  let firstName = obuserData.data.getUserDetails.edges[0].node.firstName;
  let lastName = obuserData.data.getUserDetails.edges[0].node.lastName;
  const handleBack = () => {
    try{
    if (Cookies.getJSON("ob_session")) {
      if (targetId && targetId.length > 0) {
        deleteTarget({
          variables: {
            id: Number(targetId),
            firstName: firstName,
            lastName: lastName,
          },
        })
          .then((res: any) => {
            let data = {};
            data = { refetchData: true, clientInfo: clientInfo };
            if (param?.previousPage === "client") {
              history(routeConstant.CLIENT, { state: { data } });
              localStorage.removeItem("name");
              localStorage.removeItem("customClientUrl");
              localStorage.removeItem("targetId");
              localStorage.removeItem("ipRange");
              localStorage.removeItem("ipAddress");
              localStorage.removeItem("re-runTargetName");
              localStorage.removeItem("userName");
              localStorage.removeItem("password");
              localStorage.removeItem("vpnUserName");
              localStorage.removeItem("vpnPassword");
              localStorage.removeItem("vpnFilePath");
              localStorage.removeItem("WinTargetName");
              localStorage.removeItem("LinuxTargetName");
              localStorage.removeItem("subnetMask");
              localStorage.removeItem("LinuxTargetId");
              localStorage.removeItem("winTargetId");
              localStorage.removeItem("updatetargettimestamp");
              localStorage.removeItem("WinIpAddress");
              localStorage.removeItem("initialSubnetMask");
              localStorage.removeItem("initialTargetIpRange");
              localStorage.removeItem("TargetNameTimestamp")
            } else {
              history(routeConstant.RA_REPORT_LISTING, { state: { data } });
              localStorage.removeItem("name");
              localStorage.removeItem("targetId");
              localStorage.removeItem("ipRange");
              localStorage.removeItem("ipAddress");
              localStorage.removeItem("re-runTargetName");
              localStorage.removeItem("userName");
              localStorage.removeItem("password");
              localStorage.removeItem("vpnUserName");
              localStorage.removeItem("vpnPassword");
              localStorage.removeItem("vpnFilePath");
              localStorage.removeItem("WinTargetName");
              localStorage.removeItem("LinuxTargetName");
              localStorage.removeItem("subnetMask");
              localStorage.removeItem("LinuxTargetId");
              localStorage.removeItem("winTargetId");
              localStorage.removeItem("updatetargettimestamp");
              localStorage.removeItem("WinIpAddress");
              localStorage.removeItem("initialSubnetMask");
              localStorage.removeItem("initialTargetIpRange");
              localStorage.removeItem("TargetNameTimestamp")
            }
          })
          .catch((err:any) => {
            sentry_error_catch(err,setBackdrop,setFormState)
            let data = {};
            data = { refetchData: true, clientInfo: clientInfo };
            history(routeConstant.CLIENT, { state: { data } });
            localStorage.removeItem("name");
            localStorage.removeItem("targetId");
            localStorage.removeItem("ipRange");
            localStorage.removeItem("ipAddress");
            localStorage.removeItem("re-runTargetName");
            localStorage.removeItem("userName");
            localStorage.removeItem("password");
            localStorage.removeItem("vpnUserName");
            localStorage.removeItem("vpnPassword");
            localStorage.removeItem("vpnFilePath");
            localStorage.removeItem("WinTargetName");
            localStorage.removeItem("LinuxTargetName");
            localStorage.removeItem("subnetMask");
            localStorage.removeItem("LinuxTargetId");
            localStorage.removeItem("winTargetId");
            localStorage.removeItem("updatetargettimestamp");
            localStorage.removeItem("WinIpAddress");
            localStorage.removeItem("initialSubnetMask");
            localStorage.removeItem("initialTargetIpRange");
            localStorage.removeItem("TargetNameTimestamp")
          });
      } else {
        let data = {};
        data = { refetchData: true, clientInfo: clientInfo };
        if (param?.previousPage === "client") {
          history(routeConstant.CLIENT, { state: { data } });
          localStorage.removeItem("name");
          localStorage.removeItem("targetId");
          localStorage.removeItem("ipRange");
          localStorage.removeItem("ipAddress");
          localStorage.removeItem("re-runTargetName");
          localStorage.removeItem("userName");
          localStorage.removeItem("password");
          localStorage.removeItem("vpnUserName");
          localStorage.removeItem("vpnPassword");
          localStorage.removeItem("vpnFilePath");
          localStorage.removeItem("WinTargetName");
          localStorage.removeItem("LinuxTargetName");
          localStorage.removeItem("subnetMask");
          localStorage.removeItem("LinuxTargetId");
          localStorage.removeItem("winTargetId");
          localStorage.removeItem("updatetargettimestamp");
          localStorage.removeItem("WinIpAddress");
          localStorage.removeItem("initialSubnetMask");
          localStorage.removeItem("initialTargetIpRange");
          localStorage.removeItem("TargetNameTimestamp")
        } else {
          history(routeConstant.RA_REPORT_LISTING, { state: { data } });
          localStorage.removeItem("name");
          localStorage.removeItem("targetId");
          localStorage.removeItem("ipRange");
          localStorage.removeItem("ipAddress");
          localStorage.removeItem("re-runTargetName");
          localStorage.removeItem("userName");
          localStorage.removeItem("password");
          localStorage.removeItem("vpnUserName");
          localStorage.removeItem("vpnPassword");
          localStorage.removeItem("vpnFilePath");
          localStorage.removeItem("WinTargetName");
          localStorage.removeItem("LinuxTargetName");
          localStorage.removeItem("subnetMask");
          localStorage.removeItem("LinuxTargetId");
          localStorage.removeItem("winTargetId");
          localStorage.removeItem("updatetargettimestamp");
          localStorage.removeItem("WinIpAddress");
          localStorage.removeItem("initialSubnetMask");
          localStorage.removeItem("initialTargetIpRange");
          localStorage.removeItem("TargetNameTimestamp")
        }
      }
    } else {
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };

  let data = {};
  let targetInfo = {
    targetName: name,
    host: ipRange,
    subnetMask: subnetMask,
    vpnUserName: vpnUserName,
    vpnPassword: vpnPassword,
  };

  const handleOkay = () => {
    setShowDialogBox(false);
    setTimeout(() => {
      data = {
        LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : true,
        windowsNetwork:
          param && param.windowsNetwork ? param.windowsNetwork : false,
        editData: param && param.editData ? param.editData : false,
        clientInfo: param && param.clientInfo,
        targetInfo: targetInfo,
        editLinuxData: param.editLinuxData ? param.editLinuxData : false,
        editWindowsData: param.editWindowsData ? param.editWindowsData : false,
        previousPage: param?.previousPage,
        checkboxSelected:certCheckBox,
        linuxLoginSelected: linuxLoginCheckBox,
        windowLoginSelected: windowsLoginCheckBox,
        scanName : param.scanName ?  param.scanName : '',
        targetIpRange : param.targetIpRange ? param.targetIpRange : '',
        subnetMask : param.subnetMask ? param.subnetMask : '',
      };
      history(routeConstant.LOGIN_CREDENTIALS, { state: { data } });
    }, 500);
  };

  const handleCancel = () => {
    setShowDialogBox(false);
  };

  const handleClose = () => {
    setShowDialogBox(false);
    setTimeout(() => {
      if (param) {
        data = {
          LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : true,
          windowsNetwork:
            param && param.windowsNetwork ? param.windowsNetwork : false,
          editData: param && param.editData ? param.editData : false,
          clientInfo: param && param.clientInfo,
          targetInfo: targetInfo,
          editLinuxData: param.editLinuxData ? param.editLinuxData : false,
          editWindowsData: param.editWindowsData
            ? param.editWindowsData
            : false,
          previousPage: param?.previousPage,
          checkboxSelected:certCheckBox,
          linuxLoginSelected: linuxLoginCheckBox,
          windowLoginSelected: windowsLoginCheckBox,
          scanName : param.scanName ?  param.scanName : '',
          targetIpRange : param.targetIpRange ? param.targetIpRange : '',
          subnetMask : param.subnetMask ? param.subnetMask : '',
        };
      }
      history(routeConstant.TASK_DETAILS, { state: { data } });
    }, 500);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (!/[^a-zA-Z0-9\- ]/.test(event.target.value)) {
      let value = event.target.value;
      let isErrName = value.length <= 0 ? "Required" : "";
      setIsError((isError: any) => ({
        ...isError,
        name: isErrName,
      }));
      setUploadDisabled(true);
      setFileUploaded(false);
      setSubmitDisabled(checkValidation);
    }
    if (/[^a-zA-Z0-9\- ]/.test(event.target.value)) {
      setIsError((isError: any) => ({
        ...isError,
        name: "Invalid Scan Name",
      }));
    }
  };

  const handleIpRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIpRange(event.target.value);
    let value = event.target.value;
    let isErrIpRange = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      ipRange: isErrIpRange,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
    let value = event.target.value;
    let isErrUserName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      userName: isErrUserName,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleVpnUserNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVpnUserName(event.target.value);
    let value = event.target.value;
    let isErrVpnUserName = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      vpnUserName: isErrVpnUserName,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    let value = event.target.value;
    let isErrPassword = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      password: isErrPassword,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleVpnPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVpnPassword(event.target.value);
    let value = event.target.value;
    let isErrVpnPassword = value.length <= 0 ? "Required" : "";
    setIsError((isError: any) => ({
      ...isError,
      vpnPassword: isErrVpnPassword,
    }));
    setSubmitDisabled(checkValidation);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const removeUploadFile = () => {
    setSelectedFile(null);
    setVpnFilePath(null);
    setDisplayVpnFilePath(null);
    setSubmitDisabled(true);
    onChangeHandler(fileEvent, "remove");
  };

  const onClickTestConnection = async () => {
    try{
    let certCheck = certCheckBox? "Yes":"No";
    if (param.clientInfo) {
      if (param && param.reRun === true) {
        setBackdrop(true);
        const headerObj = {
          "Content-Type": "application/json",
          Authorization: `jwt ${session}`,
        };
        const url = `${RA_TARGET_VPNTEST}target/testcredentails/?cid=${param.clientInfo.clientId}&host=${ipRange}&tname=${name}&vusername=${vpnUserName}&vpasswords=${encodeURIComponent(vpnPassword)}&testConnectType=New&authCheckboxSelected=${certCheck}&subnet=${subnetMask}`;

        await fetch(url, {
          method: "GET",
          headers: headerObj,
        })
          .then((data) => data.json())
          .then((response) => {
            setBackdrop(false);
            if (response == "VPN connected Successfully") {
              SetConnectionSuccess(true);
              setSubmitDisabled(false);
              setFormState((formState) => ({
                ...formState,
                isSuccess: true,
                isUpdate: false,
                isDelete: false,
                isFailed: false,
                errMessage: msgConstant.TEST_CONNECTION,
              }));
            } else if (response === "VPN is Connected,Please Disconnect") {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage:
                  " You are already connected with another VPN. Please disconnect then try again",
              }));
            } else if (response === "Authentication Failed") {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_AUTHENTICATION_FAILED,
              }));
            } else if (response === "Openvpn File is invalid") {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_FILE_INVALID,
              }));
            } else if (response === "Scan name is already present") {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.SCAN_NAME_EXITS_ERROR,
              }));
            } else {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.TEST_CONNECTION_FAILED,
              }));
            }
          })
          .catch((err:any) => {
            setSubmitDisabled(true);
            sentry_error_catch(err,setBackdrop,setFormState)
          });
      } else {
        setBackdrop(true);

        const headerObj = {
          "Content-Type": "application/json",
          Authorization: `jwt ${session}`,
        };

        let url = `${RA_TARGET_VPNTEST}target/testcredentails/?cid=${param.clientInfo.clientId}&host=${ipRange}&tname=${name}&vusername=${vpnUserName}&vpasswords=${encodeURIComponent(vpnPassword)}&testConnectType=New&authCheckboxSelected=${certCheck}&subnet=${subnetMask}`;

        await fetch(url, {
          method: "GET",
          headers: headerObj,
        })
          .then((data) => data.json())
          .then((response) => {
            setBackdrop(false);
            if (response === "VPN connected Successfully") {
              SetConnectionSuccess(true);
              localStorage.setItem("vpnUserName",JSON.stringify(vpnUserName));
              localStorage.setItem("vpnPassword",JSON.stringify(vpnPassword));
              setSubmitDisabled(false);
              setFormState((formState) => ({
                ...formState,
                isSuccess: true,
                isUpdate: false,
                isDelete: false,
                isFailed: false,
                errMessage: msgConstant.TEST_CONNECTION,
              }));
            } else if (response === "Authentication Failed") {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_AUTHENTICATION_FAILED,
              }));
            } else if (response === "Openvpn File is invalid") {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.VPN_FILE_INVALID,
              }));
            } else if (response === "VPN is Connected,Please Disconnect") {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage:
                  "You are already connected with another VPN. Please disconnect then try again",
              }));
            } else if (response === "Scan name is already present") {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.SCAN_NAME_EXITS_ERROR,
              }));
            } else {
              SetConnectionSuccess(false);
              setSubmitDisabled(true);
              setFormState((formState) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstant.TEST_CONNECTION_FAILED,
              }));
            }
          })
          .catch((err:any) => {
            setSubmitDisabled(true);
            sentry_error_catch(err,setBackdrop,setFormState)
          });
      }
    }}catch(error){
      showBoundary(error)
    }
  };

  const handleClickShowVpnPassword = () => {
    setShowVpnPassword(!showVpnPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleInputErrors = () => {
    let error = true;
    if(!certCheckBox){
    if (vpnUserName === "") {
      error = false;
      let isErrVpnUserName = vpnUserName.length <= 0 ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        vpnUserName: isErrVpnUserName,
      }));
     }
    }
    if (name === "") {
      error = false;
      let isErrName = name.length <= 0 ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        name: isErrName,
      }));
    }
    if (ipRange === "") {
      error = false;
      let isErrIpRange = ipRange.length <= 0 ? "Required" : "";
      setIsError((error: any) => ({
        ...error,
        ipRange: isErrIpRange,
      }));
    }
    if(!certCheckBox){
    if (vpnPassword === "") {
      error = false;
      setIsError((isError: any) => ({
        ...isError,
        vpnPassword: "Required",
      }));
    }
  }
    return error;
  };

  // const theme = createTheme({
  //   overrides: {
  //     // MuiTooltip: {
  //     //   tooltip: {
  //     //     backgroundColor:"rgb(240, 102, 1, 0.8)",
  //     //     borderRadius : "12px",
  //     //     position: "relative",
  //     //   },
  //     //   tooltipPlacementRight: {
  //     //     "&:before" : {
  //     //       content: "' '",
  //     //       width: "0px",
  //     //       height: "0px",
  //     //       borderTop: "6px solid transparent",
  //     //       borderBottom: "6px solid transparent",
  //     //       borderRight:"6px solid rgba(240, 102, 1, 0.8)",
  //     //       zIndex: "9999",
  //     //     position:"absolute",
  //     //     left:"-6px",
  //     //     top:"45%",
  //     //     }
  //     //   }
  //     // }
  //     MuiTooltip: {
  //       tooltip: {
  //         backgroundColor: "rgb(240, 102, 1, 0.8)",
  //         borderRadius: "12px",
  //         position: "relative",
  //         "&:before": {
  //           content: "' '",
  //           width: "0px",
  //           height: "0px",
  //           zIndex: 9999,
  //           position: "absolute",
  //         },
  //       },
  //       tooltipPlacementRight: {
  //         "&:before": {
  //           borderTop: "6px solid transparent",
  //           borderBottom: "6px solid transparent",
  //           borderRight: "6px solid rgba(240, 102, 1, 0.8)",
  //           left: "-6px",
  //           top: "45%",
  //         },
  //       },
  //       tooltipPlacementLeft: {
  //         "&:before": {
  //           borderTop: "6px solid transparent",
  //           borderBottom: "6px solid transparent",
  //           borderLeft: "6px solid rgba(240, 102, 1, 0.8)",
  //           right: "-6px",
  //           top: "45%",
  //         },
  //       },
  //       tooltipPlacementBottom: {
  //         "&:before": {
  //           borderLeft: "6px solid transparent",
  //           borderRight: "6px solid transparent",
  //           borderBottom: "6px solid rgba(240, 102, 1, 0.8)",
  //           left: "45%",
  //           top: "-6px",
  //         },
  //       },
  //       tooltipPlacementTop: {
  //         "&:before": {
  //           borderLeft: "6px solid transparent",
  //           borderRight: "6px solid transparent",
  //           borderTop: "6px solid rgba(240, 102, 1, 0.8)",
  //           left: "45%",
  //           bottom: "-6px",
  //         },
  //       },
  //     },
  //   },
  // });

  const handleTargetToolTipClose = () => {
    setTargetOpen(false);
  };

  const handleTargetToolTipOpen = () => {
    setTargetOpen(true);
  };
  const handlePricingBoxOpen = () => {
    setOpenPricingBox(true);
  };
  const handlePricingBoxClose = () => {
    setOpenPricingBox(false);
  };
  const checkBoxChangeHandler = (event: any) => {
    setCertCheckBox(event.target.checked);
  }
  const handleBackToTarget = () => {
    if(Cookies.getJSON("ob_session")){
      data = {
        LinuxNetwork: param && param.LinuxNetwork ? param.LinuxNetwork : true,
        windowsNetwork:
          param && param.windowsNetwork ? param.windowsNetwork : false,
        editData: param && param.editData ? param.editData : connectionSuccess,
        clientInfo: param && param.clientInfo,
        targetInfo: targetInfo,
        editLinuxData: param.editLinuxData ? param.editLinuxData : false,
        editWindowsData: param.editWindowsData ? param.editWindowsData: false,
        previousPage: param?.previousPage,
        checkboxSelected:certCheckBox,
        linuxLoginSelected: linuxLoginCheckBox,
        windowLoginSelected: windowsLoginCheckBox,
        scanName : param.scanName ?  param.scanName : '',
        targetIpRange : param.targetIpRange ? param.targetIpRange : '',
        subnetMask : param.subnetMask ? param.subnetMask : '',
        vpnfile:selectedFile ? selectedFile : null,
      };
      setTimeout(() => {
        history(routeConstant.TARGET, { state: { data } });
      }, 500);
    }else{
      logout();
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Typography component="h5" variant="h1">
        Advanced Vulnerability Assessment for{" "}
        {param !== undefined && param?.clientInfo !== undefined
          ? param?.clientInfo?.name
          : null}
        {priceData?.scannable === 1 ? (
          <>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Credits:{" "}
            {priceData.scan_available.free + priceData.scan_available.credit}
          </>
        ) : (
          ""
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <IconButton
          id="pricing-box"
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handlePricingBoxOpen}
        >
          <QuestionMarkIcon fontSize="inherit" />
        </IconButton> */}
      </Typography>
      <RaStepper />
      {taskLoading || backdrop ? <SimpleBackdrop /> : null}
      {targetLoading ? <SimpleBackdrop /> : null}
      <Grid container className={styles.backToListButtonPanel}>
        <Grid item xs={12} md={12} className={styles.backToListButton}>
          {/* <Button
            className={styles.BackToButton}
            id="back-btn"
            variant={"contained"}
            onClick={handleBackToTarget}
            color="secondary"
            data-testid="cancel-button"
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
              alt="user icon"
            />
            &nbsp; Back to List
          </Button> */}
          <div className={styles.crossBtn}>
            <Button
              id="cancel-button"
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              {"X"}
            </Button>
            <span className={styles.cancleTooltip}>Cancel</span>
          </div>
        </Grid>
      </Grid>
      <div style={{ paddingLeft: "7px" }}>
        <FormControlLabel
          control={
            <Checkbox name="cert_check" checked={certCheckBox} color="primary" onChange={checkBoxChangeHandler} />
          }
          classes={{root:styles['MuiFormControlLabel-root']}}
          label="Certificate Login"
        />
        <span style={{cursor:"pointer"}}>
          <Tooltip
            open={certToolTip}
            onClose={() => {
              setCertToolTip(false)
            }}
            placement="top"
            onOpen={() => {
              setCertToolTip(true)
            }}
            title={
              <React.Fragment>
                <p>
                  <b>Note:</b>
                  <b>
                    {"Select the Checkbox for Certificate Based Login."}
                  </b>{" "}
                </p>
                {/* {"It's very engaging. Right?"} */}
              </React.Fragment>
            }
          >
            <ContactSupportIcon className={styles.CircleIcon} />
          </Tooltip>
        </span>
      </div>
      {/* <Paper className={styles.paper}> */}
      <Grid container spacing={3} className={styles.AlertWrap}>
        <Grid item xs={12}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              id="success-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
          {formState.isUpdate ? (
            <Alert
              severity="success"
              id="success-alert2"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              severity="error"
              id="error-alert"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {FAILED}
              {formState.errMessage}
            </Alert>
          ) : null}
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={6}
          className={
            param !== undefined && param.editData
              ? styles.disfield
              : styles.inputs
          }
        >
          <span className={styles.IPTooltip}>
            <Tooltip
              open={targetOpen}
              onClose={handleTargetToolTipClose}
              onOpen={handleTargetToolTipOpen}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b> Scan Name can't contain any special characters. </b>{" "}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                id="scan-name"
                label="Scan Name"
                value={name}
                onChange={handleNameChange}
                required
                error={isError.name}
                helperText={isError.name}
                disabled={param !== undefined && param.editData}
              >
                Scan Name
              </Input>
            </Tooltip>
          </span>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <span className={styles.IPTooltip}>
            <Tooltip
              open={open}
              onClose={handleToolTipClose}
              onOpen={handleToolTipOpen}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <p>
                    <b>Please enter data in the below formats</b>{" "}
                  </p>
                  <b>{"Single IP Address"}</b>
                  <em>{"(e.g. 192.168.x.xx)"}</em>{" "}
                  <p>
                    <b>{" IP Range"}</b>{" "}
                    {
                      "(e.g. 192.168.X.1/24 or 192.168.X.1-255)"
                    }
                  </p>{" "}
                  <p>
                    <b>{"Multiple IP Addresses"}</b>{" "}
                    {
                      "(e.g.192.168.X.3,192.168.X.4)"
                    }
                  </p>{" "}
                  <p>
                    <b>For Domain/URL </b> <em>{"(e.g. domainname.com)"}</em>{" "}
                  </p>{" "}
                </React.Fragment>
              }
            >
              <Input
                type="text"
                id="url-ip-range"
                label="URL / IP Range"
                value={ipRange}
                onChange={handleIpRangeChange}
                required
                error={isError.ipRange}
                helperText={isError.ipRange}
              >
                IP Range IP Range IP Range
              </Input>
            </Tooltip>
          </span>
        </Grid> */}
        {!certCheckBox && <Grid item xs={12} md={6}>
          <Input
            type="text"
            id="vpn-username"
            label="VPN Username"
            value={vpnUserName}
            onChange={handleVpnUserNameChange}
            required
            error={isError.vpnUserName}
            helperText={isError.vpnUserName}
            autoComplete = "off"
          >
            VPN User Name
          </Input>
        </Grid>}
        {!certCheckBox && <Grid item xs={12} md={6} className={styles.PasswordField}>
          <FormControl className={styles.TextField} variant="outlined">
            <InputLabel classes={{ root: styles.FormLabel }}>
              VPN Password *
            </InputLabel>
            <OutlinedInput
              classes={{
                root: styles.InputField,
                notchedOutline: styles.InputField,
                focused: styles.InputField,
              }}
              id="vpn-password"
              type={showVpnPassword ? "text" : "password"}
              label="VPN Password *"
              value={vpnPassword}
              onChange={handleVpnPasswordChange}
              autoComplete = "off"
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowVpnPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showVpnPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {isError.vpnPassword ? (
              <FormHelperText
                error={isError.vpnPassword}
                classes={{ root: styles.FormHelperText }}
              >
                Required
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>}
        {/* <Grid item xs={12} md={6} className={styles.upBtn}> */}
        <Grid item xs={12} md={6}>
          <form>
            <label className={styles.lawDocument}>VPN Config File </label>
            <span className={styles.UploadTooltip}>
              <ThemeProvider theme={theme}>
                <Tooltip
                  open={uploadToolOpen}
                  onClose={handleUploadToolTipClose}
                  onOpen={handleUploadToolTipOpen}
                  title={
                    <React.Fragment>
                      <p>
                        <b>Note:</b>
                        <b>
                          {
                            "The system accepts only .ovpn  and .tgz file formats"
                          }
                        </b>{" "}
                      </p>
                    </React.Fragment>
                  }
                >
                  <ContactSupportIcon className={styles.CircleIcon} />
                </Tooltip>
              </ThemeProvider>
            </span>
            <input
              id="fileUpload"
              type="file"
              name="file"
              onChange={(event) => onChangeHandler(event, "")}
              className={styles.fileInput}
              accept=".ovpn,.tgz"
            />
            {selectedFile || displayVpnFilePath ? (
              <div className={styles.uploadLabelWrap}>
                <div className={styles.uploadLabelName}>Uploaded File :</div>
                <div className={styles.uploadedLabel}>
                  {selectedFile ? selectedFile.name : displayVpnFilePath}
                  &nbsp;&nbsp;
                </div>
                <Button
                  variant="contained"
                  id="remove-file-upload"
                  color="secondary"
                  onClick={removeUploadFile}
                  className={styles.CloseButton}
                >
                  <CancelIcon />
                </Button>
              </div>
            ) : (
              <label htmlFor="fileUpload" className={styles.fileUploadLable}>
                Upload File
              </label>
            )}
          </form>
        </Grid>

        <Grid item xs={12} className={styles.ActionButtons}>
          <AlertBox
            DialogTitle={""}
            open={showDialogBox}
            dialogBoxMsg={dialogBoxMsg}
            pathName={""}
            handleOkay={handleOkay}
            cancelButtonPath={""}
            closeButtonPath={""}
            buttonName={"Yes"}
            CloseButtonName={"No"}
            handleCancel={handleCancel}
            handleClose={handleClose}
          ></AlertBox>
          <Button
            className={styles.borderLess}
            id="back-btn-to-target"
            variant={"contained"}
            onClick={handleBackToTarget}
            color="primary"
            data-testid="back-button"
          >
            Back
          </Button>
          <Button
            type="button"
            id="test-connection-btn"
            color="primary"
            variant={"contained"}
            onClick={onClickHandler2}
          >
            {param != undefined && param.editData ? "Retry" : "Test Connection"}
          </Button>
          <Button
            onClick={handleSubmitDialogBox}
            id="submit-dialogbox-btn"
            color="primary"
            variant={"contained"}
            disabled={submitDisabled}
          >
            next
          </Button>
          {/* <Button
            className={styles.borderLess}
            id="back-btn"
            variant={"contained"}
            onClick={handleBack}
            color="primary"
            data-testid="cancel-button"
          >
            cancel
          </Button> */}
        </Grid>
      </Grid>
      <DialogBox
        open={openPricingBox}
        handleOk={handlePricingBoxClose}
        handleCancel={handlePricingBoxClose}
        buttonOk={"Ok"}
        skipCancel={true}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.PricingMainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Pricing
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <div className="popup-body-wrap flex-container pricing-container">
            <div className="right-pricing-wrap">
              <div className="top-pricing-container">
                <div className="top-right-pricing-wrap detailed-tab">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Pricing per Scan</th>
                        <th>Essential</th>
                        <th>Professional</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                      <tr>
                        <td>Pen Test </td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                      <tr>
                        <td>VA - Internal</td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                      <tr>
                        <td>VA - External</td>
                        <td>$99</td>
                        <td>$79</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogBox>
    </React.Fragment>
  );
};

export default Target;
