export const SUCCESS = "has been saved successfully!";
export const DELETE = "has been deleted successfully!";
export const UPDATE = "has been updated successfully!";
export const FAILED = " An Error Occured!";
export const ALERT_MESSAGE_TIMER = 5000;

/** Notifications */
export const COMPLIANCE_NOTIFICATION = "We have found some changes to the data. Please review and rebuild Compliance for an updated compliance report.";
export const NO_COMPLIANCE_NOTIFICATION = "You don't have any compliance. Please fill necessary data & build compliance report.";
export const LINUX_NETWORK_CREDENTIALS = "Do you have a Linux Domain ?";
export const LOGIN_CREDENTIALS = "Do you have a Login Credentails ?";
export const WINDOWS_NETWORK_CREDENTIALS = "Do you have a Windows Domain ?";

// ToolTip Messages
export const Queued = "The scan has been queued."
export const Scheduled = "The task has been scheduled for scanning based on the time band scheduled. Once the scan starts, the status will be updated.";
export const ScanCompleted = "The task has successfully run and generated data for assessment. The report generation process should being shortly.";
export const GeneratingReport = "Assessment reports are being generated based on the information gathered during the scan/test. The report will be available for download within the next 48 hours.";
export const ResultGenerated = "Penetration Test has successfully run and generated data for assessment. The report generation process should being shortly.";
export const ReportGenerated = "Assessment reports have been generated and are now available for download.";
export const TestFailed = "The task was unsuccessful due to an error. Please contact partner support.";
export const InProgress = "The task is currently being scanned and assessed for recommendations.";

// Pentest Messages
export const LOW = "Perform attack on your url with Low threshold and Low strength."
export const MEDIUM = "Perform attack on your url with Medium threshold and Medium strength."
export const HIGH = "Perform attack on your url with High threshold and Insane strength."

// Scan
export const SCAN_SUCCESS_MSG = "Your scan has been successfully queued. In most cases, the scans will be completed and reports ready within 3 business days. In some cases the scans may take up to 10 business days to complete. Once completed, you will receive an email notification and the report will be available within the platform."
// Error
export const ERROR_MESSAGE  = " Something Went Wrong. Please try Again !"

// alert message
export const API_FAILED_MSG = " Failed to create Scan Please Try Again";
export const SCAN_NAME_EXITS_ERROR = " Scan name exists. Add another name";
export const VALID_SCAN_NAME = " Please enter valid scan name";
export const REQUIRED_ALL_FIELD = " Please fill in all the required fields";
export const SELECT_SCHEDULING_INTERVAL = "Select the Interval for scheduling";
export const SELECT_SCHEDULING_MONTH = "Select the Month for scheduling";
export const SELECT_SCHEDULING_DAY = "Select Valid Start Day for scheduling";
export const SELECT_SCHEDULING_SCAN_NUMBER = "Select the No.of scan for scheduling";
export const SCHEDULED_SCAN_SUCCESS = "Scan Scheduled successfully";
export const SCAN_CREATED_SUCCESS = "Scan created successfully";
export const SCAN_NAME_EMPTY = " Please enter scan name";
export const SCAN_DETAIL_DATE_ERR = "Start Date must be smaller than EndDate";
export const SOFT_DELETE_FAILED = " Unable to delete ";
export const SOFT_DELETE_POPUP_MSG = "Are you sure you want to remove ";
export const ADD_SCHEDULING_SUCCESS = " Scheduled Successfully";
export const ADD_SCHEDULING_FAILED = " Scheduling Failed";
export const EDIT_SCHEDULING_FAILED = " Edit Failed";
export const IN_VALID_TIME = " In-valid Time Selected";
export const VPN_FILE_UPLOAD_FAILED = " File Uploaded Failed";
export const VPN_FILE_INVALID_FORMAT = " Upload a file in the proper format";
export const SELECT_VPN_FILE = " Please select the file to upload";
export const TEST_CONNECTION = " Test Connection Successful"; 
export const VPN_AUTHENTICATION_FAILED = " Authentication Failed";
export const VPN_FILE_INVALID = " Invalid file";
export const TEST_CONNECTION_FAILED = " Test Connection Failed"; 
export const TARGET_UPDATE_SUCCESS = "Target Updated Successfully !"
export const TARGET_CREATED_SUCCESS = "Scan Created Successfully"; 
export const GUEST_CLIENT_NAME_EMPTY = "Name is Required";
export const GUEST_CLIENT_EMAIL_EMPTY = "Email is Required";
export const GUEST_CLIENT_EMAIL_INVALID = " Please enter valid email address";
export const ENTER_VALID_URL = "Please enter valid URL/IP";
export const EMPTY_URL = "Please enter URL/IP";
export const VALID_IP_ADDRESS = " Please Enter Valid IP Address";
export const VALID_DOMAIN =  " Please Enter Valid Domain Name";
export const VPN_VALIDATION = " There is an error in the uploaded vpn configuration file. The configuration file contains an invalid definition 'redirect-gateway def1'. Kindly correct the file and re-upload."
export const VPN_BACKEND_RESPONSE = "There is an error in the uploaded vpn configuration file. The configuration file contains an invalid definition 'redirect-gateway def1'. Kindly correct the file and re-upload."
//ADMIN SIDE MESSAGES
export const SEARCH_PARTNER_NAME_EMPTY = "Please Enter the Partner Name"; 
export const PARTNER_DELETE_SUCCESS = "Partner has been Deleted Successfully!";
export const PARTNER_DELETE_FAILED = "Failed to Delete Partner"; 
export const SEARCH_CLIENT_NAME_EMPTY = "Please Enter the Client Name"; 
export const CLIENT_DELETE_SUCCESS = "Client has been Deleted Successfully!";
export const CLIENT_DELETE_FAILED = "Failed to Delete Client"; 
export const SEARCH_TARGET_NAME_EMPTY = "Please Enter the Target Name"; 
export const PARTNER_USER_DELETE_SUCCESS = "User has been Deleted Successfully! "
export const PARTNER_USER_DELETE_FAILED = "Failed to Delete User"; 
export const SEARCH_USER_NAME_EMPTY = "Please Enter the Partner User Name"; 
export const SELECT_START_DATE = "Please Select the StartDate Field";
export const SELECT_END_DATE = "Please Select the EndDate Field";
export const INCORRECT_CREDENTIALS = "Incorrect username or password";
export const ENTER_PASSWORD = "Please enter a password";