import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./containers/Dashboard/Dashboard";
import Target from "./containers/RiskAssessment/Target/Target";
import Windows_Network from "./containers/RiskAssessment/Windows_Network/Windows_Network";
import Linux_Network from "./containers/RiskAssessment/Linux_Network/Linux_Network";
import TaskDetails from "./containers/RiskAssessment/TaskDetails/TaskDetails";
import ReportStatus from "./containers/RiskAssessment/ReportStatus/ReportStatus";
import RaReportListing from "./containers/RiskAssessment/RaReportListing/RaReportListing";
import Logout from "./containers/Auth/Logout/Logout";
import PartnerUser from "./containers/AdminPanel/PartnerUser/PartnerUser/PartnerUser";
import PartnerUserForm from "./containers/AdminPanel/PartnerUser/PartnerUserForm/PartnerUserForm";
import PartnerForm from "./containers/AdminPanel/Partner/PartnerForm/PartnerForm";
import * as routeConstants from "./common/RouteConstants";
import Client from "./containers/Client/Client/Client";
import ClientForm from "./containers/Client/ClientForm/ClientForm";
import TopStepperRoute from "./common/TopStepperRoute";
import Layout from "./components/UI/Layout/Layout";
import AdvanceTarget from "./containers/RiskAssessment/Target/AdvancedTarget/AdvanceTarget";
import PenTest from "./containers/PenTest/PenTest";
import Prospects from "./containers/Prospects/Prospects";
import ProfileSettings from "./containers/ProfileSettings/ProfileSettings";
import MainTarget from "./containers/RiskAssessment/Target/MainTarget/MainTarget";
import LoginCredential from "./containers/RiskAssessment/LoginCredentail/LoginCredentail";
import ScanDetails from "./containers/RiskAssessment/ScanDetails/ScanDetails";
import AboutSection from "./containers/AboutSection/AboutSection";
// import AdvancedPenTest from "./containers/AdvancedPenTest/AdvancedPenTest";

const defaultRedirect = () => <Navigate to={routeConstants.CLIENT} />;

export const AuthRoutes = (
  <Routes>
    <Route
      path={routeConstants.PEN_TEST}
      element={
        <Layout>
          <PenTest />
        </Layout>
      }
    />
    {/* <Route
      path={routeConstants.ADVANCED_PEN_TEST}
      element={
        <Layout>
          <AdvancedPenTest />
        </Layout>
      }
    /> */}
    <Route
      path={routeConstants.ABOUTSECTION}
      element={
        <Layout>
          <AboutSection />
        </Layout>
      }
    />
    <Route
      path={routeConstants.PARTNER_USER_FORM}
      element={
        <Layout>
          <PartnerUserForm />
        </Layout>
      }
    />
    <Route
      path={routeConstants.PARTNER_USER_FORM_ADD}
      element={
        <Layout>
          <PartnerUserForm />
        </Layout>
      }
    />
    <Route
      path={routeConstants.PARTNER_USER_FORM_EDIT + ":id"}
      element={
        <Layout>
          <PartnerUserForm />
        </Layout>
      }
    />
    <Route
      path={routeConstants.PARTNER_USER_FORM_EDIT + "/:id "}
      element={
        <Layout>
          <PartnerUserForm />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CLIENT_FORM}
      element={
        <Layout>
          <ClientForm />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CLIENT_FORM_ADD}
      element={
        <Layout>
          <ClientForm />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CLIENT_FORM_EDIT + ":id"}
      element={
        <Layout>
          <ClientForm />
        </Layout>
      }
    />
    <Route
      path={routeConstants.DASHBOARD}
      element={
        <Layout>
          <Dashboard />
        </Layout>
      }
    />
    <Route
      path={routeConstants.CLIENT}
      element={
        <Layout>
          <Client />
        </Layout>
      }
    />
    <Route
      path={routeConstants.PARTNER_USER}
      element={
        <Layout>
          <PartnerUser />
        </Layout>
      }
    />
    <Route
      path={routeConstants.LOGOUT_URL}
      element={
        <Layout>
          <Logout />
        </Layout>
      }
    />
    <Route
      path={routeConstants.TARGET}
      element={
        <Layout>
          <MainTarget />
        </Layout>
      }
    />
    <Route
      path={routeConstants.VPN_CONNECT}
      element={
        <Layout>
          <Target />
        </Layout>
      }
    />
    <Route
      path={routeConstants.LOGIN_CREDENTIALS}
      element={
        <Layout>
          <LoginCredential />
        </Layout>
      }
    />
    <Route
      path={routeConstants.EXTERNAL_TARGET}
      element={
        <Layout>
          <AdvanceTarget />
        </Layout>
      }
    />
    <Route
      path={routeConstants.WINDOWS_NETWORK}
      element={
        <Layout>
          <Windows_Network />
        </Layout>
      }
    />
    <Route
      path={routeConstants.LINUX_NETWORK}
      element={
        <Layout>
          <Linux_Network />
        </Layout>
      }
    />
    <Route
      path={routeConstants.TASK_DETAILS}
      element={
        <Layout>
          <TaskDetails />
        </Layout>
      }
    />
    <Route
      path={routeConstants.REPORT_STATUS}
      element={
        <Layout>
          <ReportStatus />
        </Layout>
      }
    />
    <Route
      path={routeConstants.RA_REPORT_LISTING}
      element={
        <Layout>
          <RaReportListing />
        </Layout>
      }
    />
    <Route
      path={routeConstants.VIEW_PROSPECT}
      element={
        <Layout>
          <Prospects />
        </Layout>
      }
    />
    <Route
      path={routeConstants.SETTINGS}
      element={
        <Layout>
          <ProfileSettings />
        </Layout>
      }
    />
    <Route
      path={routeConstants.SCAN_DETAILS}
      element={
        <Layout>
          <ScanDetails />
        </Layout>
      }
    />
    <Route path="/" Component={defaultRedirect} />
  </Routes>
);
