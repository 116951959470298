import React, { useState, useEffect } from "react";
import styles from "./ProfileSettings.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { Button } from "../../components/UI/Form/Button/Button";
import AutoCompleteDropDown from "../../components/UI/Form/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@date-io/date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  PARTNER_SCHEDULE,
  PARTNER_SCHEDULE_DELETE,
  PARTNER_SCHEDULE_EDIT,
} from "../../graphql/mutations/PartnerSchedule";
import Cookies from "js-cookie";
import logout from "../Auth/Logout/Logout";
import {
  SUCCESS,
  FAILED,
  ALERT_MESSAGE_TIMER,
  ERROR_MESSAGE,
} from "../../common/MessageConstants";
import Alert from "../../components/UI/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SimpleBackdrop from "../../components/UI/Layout/Backdrop/Backdrop";
import { GET_PARTNER_SCHEDULE } from "../../graphql/queries/PartnerSchedule";
import MaterialTable from "../../components/UI/Table/MaterialTable";
import Input from "../../components/UI/Form/Input/Input";
import AlertBox from "../../components/UI/AlertBox/AlertBox";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import AlarmIcon from "@mui/icons-material/AddAlarm";
import { DialogBox } from "../../components/UI/DialogBox/DialogBox";
import moment from "moment";
import { Info } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import * as msgConstant from "../../common/MessageConstants";
import { sentry_error_catch } from "../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";

export const ProfileSettings: React.FC = (props: any) => {
  const { showBoundary } = useErrorBoundary();
  const partner = Cookies.get("ob_partnerData") || logout();

  const days = [
    { id: 1, title: "Monday", value: "Monday" },
    { id: 2, title: "Tuesday", value: "Tuesday" },
    { id: 3, title: "Wednesday", value: "Wednesday" },
    { id: 4, title: "Thursday", value: "Thursday" },
    { id: 5, title: "Friday", value: "Friday" },
    { id: 6, title: "Saturday", value: "Saturday" },
    { id: 7, title: "Sunday", value: "Sunday" },
  ];
  const column = [
    { title: "Partner Name", key: "partnerName" },
    { title: "Time Zone", key: "tZone" },
    { title: "Day", key: "StartDay" },

    { title: "Start Time", key: "startTime" },
    { title: "End Time", key: "endTime" },
    {
      key: "edit",
      title: "",
      icon: (
        <img
          className={styles.EditIcon}
          src={process.env.PUBLIC_URL + "/icons/svg-icon/edit.svg"}
          alt="edit icon"
        />
      ),
      tooltip: "Edit",
      displayIcon: (rowData: any) => true,
    },
    {
      key: "delete",
      title: "",
      icon: (
        <img
          className={styles.EditIcon}
          src={process.env.PUBLIC_URL + "/icons/svg-icon/delete.svg"}
          alt="delete icon"
        />
      ),
      tooltip: "Delete",
      displayIcon: (rowData: any) => true,
    },
  ];
  const [startDay, setStartDay] = useState<any>({
    id: null,
    Name: "",
    value: "",
    startDayArray: [],
  });
  const [startTime, setStartTime] = React.useState<any>(null);
  const [startTimeValue, setStartTimeValue] = React.useState<any>("");
  const [timezone, setTimezone] = React.useState<any>();
  const [endTime, setEndTime] = React.useState<any>(null);
  const [endTimeValue, setEndTimeValue] = React.useState<any>("");
  const [isError, setIsError] = useState<any>({
    startTimeValue: "",
    endTimeValue: "",
    startDay: "",
  });
  const [backdrop, setBackdrop] = useState(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const [newData, setNewData] = useState([]);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [dialogBoxMsg, setDialogBoxMsg] = useState("");
  const [rowData2, setRowData] = useState<any>({});
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<any>("");
  const [endTimeChanged, setEndTimeChanged] = useState<boolean>(false);
  const [startTimeChanged, setStartTimeChanged] = useState<boolean>(false);
  const [errorEndDate, setErrorEndDate] = useState<any>("");
  const [createPartnerSchedule] = useMutation(PARTNER_SCHEDULE);
  const [deletePartnerSchedule] = useMutation(PARTNER_SCHEDULE_DELETE);
  const [editPartnerSchedule] = useMutation(PARTNER_SCHEDULE_EDIT);
  const [startClockOpen, setClockOpen] = useState(false);
  const [endClockOpen, setEndClockOpen] = useState(false);
  const [getPartnerSchedule, { data: ipData, loading: ipLoading }] =
    useLazyQuery(GET_PARTNER_SCHEDULE, {
      fetchPolicy: "cache-and-network",
      onCompleted: (data: any) => {
        createTableDataObject(data.getPartnerSchedule.edges);
      },
      onError: (err:any) => {
        sentry_error_catch(err,setBackdrop,setFormState)
        // logout();
      },
    });
  useEffect(() => {
    let partnerData = JSON.parse(partner);
    getPartnerSchedule({
      variables: {
        partnerId_PartnerName:
          partnerData.data.getPartnerUserDetails.edges[0].node.partnerId
            .partnerName,
        // client_type: "Prospect",
      },
    });
    setTimezone(
      partnerData.data.getPartnerUserDetails.edges[0].node.partnerId.tZone
    );
  }, []);

  const handleOpen = () => {
    setStartTime(null);
    setEndTime(null);
  };

  useEffect(() => {
    if (startClockOpen && !startTimeChanged) {
      let today = new Date().toISOString().slice(0, 10);
      let dataaa: any = today.toString().split("-");
     
      setTimeout(() => {
        setClockOpen(false);
        let splitPartsrawStartTime = rowData2.rawStartTime?.split(":");
        if(splitPartsrawStartTime){
          setStartTime(
            new Date(
              dataaa[0],
              dataaa[1],
              dataaa[2],
              splitPartsrawStartTime[0],
              splitPartsrawStartTime[1]
            )
          );
        }
      }, 550);
    }
  }, [startClockOpen]);

  useEffect(() => {
    if (endClockOpen && !endTimeChanged) {
      let today = new Date().toISOString().slice(0, 10);
      let dataaa: any = today.toString().split("-");
      setTimeout(() => {
        setEndClockOpen(false);
        let splitPartsrawEndTime = rowData2.rawEndTime?.split(":");
        if(splitPartsrawEndTime){
          setEndTime(
            new Date(
              dataaa[0],
              dataaa[1],
              dataaa[2],
              splitPartsrawEndTime[0],
              splitPartsrawEndTime[1]
            )
          );
        }
      }, 550);
    }
  }, [endClockOpen]);

  const handleStartTimeClose = () => {
    setClockOpen(true);
  };

  const handleEndClose = () => {
    setEndClockOpen(true);
  };

  const handleStartTimeChange = (event: any) => {
    if (event && event["$d"]) {
      setStartTimeChanged(true);
      setStartTimeValue(
        moment(new Date(event["$d"])).format("DD/MM/YYYY HH:mm")
      );
      setStartTime(event["$d"]);
    }
  };


  const handleEndTimeChange = (event: any) => {
    if (event && event["$d"]) {
      setIsError((error: any) => ({
        ...error,
        endTimeValue: "",
      }));
      setEndTimeChanged(true);
      setEndTime(event["$d"]);
      setEndTimeValue(moment(new Date(event["$d"])).format("DD/MM/YYYY HH:mm"));
      let startTime = startTimeValue;
      let endTime = moment(new Date(event["$d"])).format("DD/MM/YYYY HH:mm");
      let endTimeMoment = moment(endTime, "DD/MM/YYYY HH:mm");
      let startTimeMoment = moment(startTime, "DD/MM/YYYY HH:mm");

      // Check if the start time is greater than the end time (crossing midnight)
      if (startTimeMoment.isAfter(endTimeMoment)) {
        endTimeMoment.add(1, "days"); // Add 1 day to the end time
      }

      let hours = moment
        .duration(endTimeMoment.diff(startTimeMoment))
        .asHours();

      if (startTime == endTime) {
        setIsError((error: any) => ({
          ...error,
          endTimeValue: "Start time and End time should not be the same",
        }));
      } else if (hours < 8) {
        setIsError((error: any) => ({
          ...error,
          endTimeValue: `The time difference between start and end time should be at least 8 hours.`,
        }));
      }
    }
  };

  const handleTimeValidation = () => {
    setIsError((error: any) => ({
      ...error,
      endTimeValue: "",
    }));
    let rflag = true;
    let startTime = startTimeValue;
    let endTime = endTimeValue;
    let endTimeMoment = moment(endTime, "DD/MM/YYYY HH:mm");
    let startTimeMoment = moment(startTime, "DD/MM/YYYY HH:mm");

    // Check if the start time is greater than the end time (crossing midnight)
    if (startTimeMoment.isAfter(endTimeMoment)) {
      endTimeMoment.add(1, "days"); // Add 1 day to the end time
    }

    let hours = moment.duration(endTimeMoment.diff(startTimeMoment)).asHours();
   
    if (startTimeValue == endTimeValue) {
      rflag = false;
      setIsError((error: any) => ({
        ...error,
        endTimeValue: "Start time and End time should not be the same",
      }));
    } else if (hours < 8) {
      rflag = false;
      setIsError((error: any) => ({
        ...error,
        endTimeValue: `The time difference between start and end time should be at least 8 hours.${hours}`,
      }));
    }
    return rflag;
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };

  const handleCheckElement = (event: any) => {
    if (event.target.checked) {
      if (startDay.startDayArray)
        if (startDay.startDayArray.includes(event.target.name)) {
        } else {
          setStartDay((startDay: any) => ({
            ...startDay,
            startDayArray: [...startDay.startDayArray, event.target.name],
          }));
        }
    } else {
      if (startDay.startDayArray)
        if (startDay.startDayArray.includes(event.target.name)) {
          let array = [...startDay.startDayArray]; // make a separate copy of the array
          let index = array.indexOf(event.target.name);
          if (index !== -1) {
            array.splice(index, 1);
            setStartDay((startDay: any) => ({
              ...startDay,
              startDayArray: array,
            }));
          }
        }
    }
  };

  const handleSubmitDialogBox = () => {
    try{
    setBackdrop(true);
    if (Cookies.getJSON("ob_session")) {
      if (startTime && endTimeValue) {
        if (handleTimeValidation()) {
          if (!edit) {
            let partnerData = JSON.parse(partner);
            const parsedStartTimeDate = moment(
              startTimeValue,
              "DD/MM/YYYY HH:mm"
            );
            const parsedEndTimeDate = moment(endTimeValue, "DD/MM/YYYY HH:mm");
            let formattedStartTime = parsedStartTimeDate.format("HH:mm:ss");
            let formattedEndTime = parsedEndTimeDate.format("HH:mm:ss");
            let input = {
              partnerid:
                partnerData.data.getPartnerUserDetails.edges[0].node.partnerId
                  .id,
              startDayList: startDay.startDayArray,
              startTime: formattedStartTime,
              endTime: formattedEndTime,
              tZone:
                partnerData.data.getPartnerUserDetails.edges[0].node.partnerId
                  .tZone,
            };
            setBackdrop(false);
            createPartnerSchedule({
              variables: {
                input,
              },
            })
              .then((userRes) => {
                setIsError((error: any) => ({
                  ...error,
                  endTimeValue: "",
                }));
                setShowDialogBox(false);
                setEndTimeChanged(false);
                setShowDialogBox(false);
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: true,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: false,
                  errMessage: msgConstant.ADD_SCHEDULING_SUCCESS,
                }));
                setIsError((error: any) => ({
                  ...error,
                  endTimeValue: "",
                }));

                let partnerData = JSON.parse(partner);
                getPartnerSchedule({
                  variables: {
                    // orderBy: "client_name",
                    partnerId_PartnerName:
                      partnerData.data.getPartnerUserDetails.edges[0].node
                        .partnerId.partnerName,
                    // client_type: "Prospect",
                  },
                });
              })
              .catch((err) => {
                sentry_error_catch(err,setBackdrop,setFormState,msgConstant.ADD_SCHEDULING_FAILED)
                
              });
          }
          if (edit) {
            const parsedStartTimeDate = moment(
              startTimeValue,
              "DD/MM/YYYY HH:mm"
            );
            const parsedEndTimeDate = moment(endTimeValue, "DD/MM/YYYY HH:mm");
            let formattedStartTime = parsedStartTimeDate.format("HH:mm:ss");
            let formattedEndTime = parsedEndTimeDate.format("HH:mm:ss");
            let input = {
              partnerScheduleId: parseInt(editValue.id),
              startDay: startDay.value,
              startTime: formattedStartTime,
              endTime: formattedEndTime,
            };
            editPartnerSchedule({
              variables: {
                input,
              },
            })
              .then((userRes) => {
                setShowDialogBox(false);
                setEndTimeChanged(false);
                setShowDialogBox(false);
                setBackdrop(false);
                setFormState((formState) => ({
                  ...formState,
                  isSuccess: true,
                  isUpdate: false,
                  isDelete: false,
                  isFailed: false,
                  errMessage: msgConstant.ADD_SCHEDULING_SUCCESS,
                }));
                let partnerData = JSON.parse(partner);
                getPartnerSchedule({
                  variables: {
                    // orderBy: "client_name",
                    partnerId_PartnerName:
                      partnerData.data.getPartnerUserDetails.edges[0].node
                        .partnerId.partnerName,
                    // client_type: "Prospect",
                  },
                });
              })
              .catch((err:any) => {
                sentry_error_catch(err,setBackdrop,setFormState,msgConstant.EDIT_SCHEDULING_FAILED)
              });
          }
        } else {
          setBackdrop(false);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            errMessage: msgConstant.IN_VALID_TIME,
          }));
        }
      } else {
        setBackdrop(false);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          errMessage: " Please fill in the required fields",
        }));
      }
    } else {
      console.log("error while submitting data");
      logout();
    }}catch(error){
      showBoundary(error)
    }
  };

  function tConvert(time: any) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct

      time = time.slice(1); // Remove full string match value
      // To remove seconds from time
      time.splice(-1, 1);
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const createTableDataObject = (data: any) => {
    const arr: any = [];
    data.map((element: any) => {
      const obj: any = {};
      obj["id"] = element.node.id;
      obj["partnerName"] = element.node.partner.partnerName;
      obj["StartDay"] = element.node.startDay;
      obj["startTime"] = tConvert(element.node.startTime);
      obj["rawStartTime"] = element.node.startTime;
      obj["endTime"] = tConvert(element.node.endTime);
      obj["rawEndTime"] = element.node.endTime;
      obj["tZone"] = element.node.tZone;
      arr.push(obj);
    });

    setNewData(arr);
  };

  const handleOkay = () => {
    setShowDialogBox(false);
  };

  const handleAddNewSchedule = () => {
    setEdit(false);
    setStartTime(null);
    setStartTimeValue("");
    setEndTime(null);
    setEndTimeValue("");
    setStartDay({
      id: null,
      Name: "",
      value: "",
      startDayArray: [],
    });
    handleAlertClose();
    setShowDialogBox(true);
    setDialogBoxMsg("");
  };

  const handleEditNewSchedule = (rowData: any) => {
    setStartTimeChanged(false);
    setEndTimeChanged(false);
    setEditValue(rowData);
    let splitPartsrawStartTime = rowData?.rawStartTime?.split(":");
    let splitPartsrawEndTime = rowData?.rawEndTime?.split(":");
    setEdit(true);
    // new Date(1970, 1, 1, splitParts[0], splitParts[1]);
    let today = new Date().toISOString().slice(0, 10);
    let dataaa: any = today.toString().split("-");
    setStartTime(
      new Date(
        dataaa[0],
        dataaa[1],
        dataaa[2],
        splitPartsrawStartTime[0],
        splitPartsrawStartTime[1]
      )
    );
    setStartTimeValue(
      moment(
        new Date(
          dataaa[0],
          dataaa[1] - 1,
          dataaa[2],
          splitPartsrawStartTime[0],
          splitPartsrawStartTime[1]
        )
      ).format("DD/MM/YYYY HH:mm")
    );
    setEndTime(
      new Date(
        dataaa[0],
        dataaa[1],
        dataaa[2],
        splitPartsrawEndTime[0],
        splitPartsrawEndTime[1]
      )
    );
    setEndTimeValue(
      moment(
        new Date(
          dataaa[0],
          dataaa[1] - 1,
          dataaa[2],
          splitPartsrawEndTime[0],
          splitPartsrawEndTime[1]
        )
      ).format("DD/MM/YYYY HH:mm")
    );
    let startDayId = 1;
    if (rowData.StartDay === "Sunday") {
      startDayId = 7;
    }
    if (rowData.StartDay === "Monday") {
      startDayId = 1;
    }
    if (rowData.StartDay === "Tuesday") {
      startDayId = 2;
    }
    if (rowData.StartDay === "Wednesday") {
      startDayId = 3;
    }
    if (rowData.StartDay === "Thursday") {
      startDayId = 4;
    }
    if (rowData.StartDay === "Friday") {
      startDayId = 5;
    }
    if (rowData.StartDay === "Satuday") {
      startDayId = 6;
    }
    setStartDay({
      id: startDayId,
      title: rowData.StartDay,
      value: rowData.StartDay,
      startDayAyyar: [rowData.StartDay],
    });
    setTimezone(rowData.tZone);
    handleAlertClose();
    setShowDialogBox(true);
    setDialogBoxMsg("");
  };

  const handleCancel = () => {
    setStartTime(null);
    setEndTime(null);
    setStartTimeChanged(false);
    setEndTimeChanged(false);
    setShowDialogBox(false);
    setIsError((error: any) => ({
      ...error,
      endTimeValue: "",
    }));
  };

  const handleClose = () => {
    setShowDialogBox(false);
  };

  const onRowClick = (rowData: any, key: any) => {
    if (key === "delete") {
      // deletePartnerSchedule{
      handleAlertClose();
      // setShowBackdrop(true);
      setOpenDialogBox(true);
      // setDialogBoxMsg(msgConstant.LINUX_NETWORK_CREDENTIALS);
      setDialogBoxMsg(msgConstant.SOFT_DELETE_POPUP_MSG + "?");
      setRowData(rowData);
    }
    if (key === "edit") {
      setRowData(rowData);
      handleEditNewSchedule(rowData);
    }
  };
  const closeDialogBox = () => {
    // setShowBackdrop(false);
    setOpenDialogBox(false);
  };

  const confirmDelete = async () => {
    closeDialogBox();
    // SetTargetDeleted(false);
    deletePartnerSchedule({
      variables: {
        id: rowData2.id,
      },
    })
      .then((res: any) => {
        if (
          res.data.deletePartnerShedule.status ==
          "Partner Schedule Deleted Successfully"
        ) {
          let partnerData = JSON.parse(partner);
          getPartnerSchedule({
            variables: {
              partnerId_PartnerName:
                partnerData.data.getPartnerUserDetails.edges[0].node.partnerId
                  .partnerName,
            },
          });

          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: true,
            isFailed: false,
            errMessage: "    ",
          }));
        }
      })
      .catch((err:any) => {
        sentry_error_catch(err,setBackdrop,setFormState)
      });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid item xs={6}>
        <Typography component="h5" variant="h1">
          Scheduling Configuration
        </Typography>
        {/* {showDialogBox ?  */}

        <Tooltip
          placement="right"
          title={
            <React.Fragment>
              <b> A week is defined as Monday to Sunday. </b>
              <b>
                {" "}
                <p>You can add two or more schedules.</p>{" "}
              </b>{" "}
              e.g: (If you want to schedule the task from Monday to Friday
              at Partner time 10:00 to 19:00, you can use start day as Monday and
              end day as Friday with start time 10:00 to end time 19:00).
            </React.Fragment>
          }
        >
          <Info className={styles.CircleIcon2} />
        </Tooltip>
        {/* :null } */}
      </Grid>
      {/* </Grid> */}
      {!showDialogBox ? (
        <Grid container className={styles.backToListButtonPanel}>
          <Grid item xs={12} md={12} className={styles.backToListButton}>
            <Button
              className={styles.BackToButton}
              id="back-btn"
              variant={"contained"}
              onClick={() => {
                window.history.back();
              }}
              color="secondary"
              data-testid="cancel-button"
            >
              <img
                src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
                alt="user icon"
              />
              &nbsp; Back
            </Button>
            <Button
              color="primary"
              id="add-schedule-btn"
              variant="contained"
              onClick={handleAddNewSchedule}
              className={styles.ActionButton}
            >
              <AddToPhotosIcon className={styles.EditIcon} />
              &nbsp; Schedule
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {backdrop ? <SimpleBackdrop /> : null}
      {showDialogBox ? (
        <>
          <Grid item xs={12}>
            {formState.isFailed ? (
              <Alert
                severity="error"
                id="error-alert"
                action={
                  <IconButton
                    id="close"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
            <Paper className={styles.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} className={styles.disfield}>
                  <Input
                    type="text"
                    id="timezone"
                    label="Time Zone"
                    value={timezone}
                    required
                    disabled={true}
                  >
                    Time Zone
                  </Input>
                </Grid>
                {edit ? (
                  <>
                    <Grid item xs={12} md={4} className={styles.disfield}>
                      <Input
                        type="text"
                        label="Day"
                        id="start-day"
                        value={startDay.value ? startDay.value : null}
                        disabled={true}
                      >
                        Day
                      </Input>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </>
                ) : (
                  <Grid item xs={12} className={styles.ConfigItem}>
                    {days.map((obj: any, i: any) => {
                      return (
                        <FormControlLabel
                          className={styles.CheckboxLabel}
                          key={obj.title}
                          control={
                            <Checkbox
                              id={obj.title}
                              value=""
                              onChange={handleCheckElement}
                              name={obj.title}
                            />
                          }
                          label={obj.title}
                        />
                      );
                    })}
                  </Grid>
                )}
                <Grid item xs={12} md={4} className={styles.timeZoneWrap}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      onOpen={() => setStartTime(null)}
                      label={"Start Time"}
                      views={["hours", "minutes"]}
                      value={startTime}
                      onChange={handleStartTimeChange}
                      onClose={handleStartTimeClose}
                      className={styles.ReactInput}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      onOpen={() => setEndTime(null)}
                      label={"End Time"}
                      views={["hours", "minutes"]}
                      value={endTime}
                      onError={isError.endTimeValue}
                      onChange={handleEndTimeChange}
                      slotProps={{
                        textField: {
                          helperText: isError.endTimeValue,
                        },
                      }}
                      className={styles.ReactInput}
                      onClose={handleEndClose}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} className={styles.ActionButtons}>
            <Button
              onClick={handleSubmitDialogBox}
              color="primary"
              id="submit-btn"
              variant={"contained"}
              data-testid="ok-button"
              disabled={
                !edit
                  ? startDay.startDayArray
                    ? startDay.startDayArray.length === 0
                    : true
                  : false
              }
            >
              Save
            </Button>
            <Button
              className={styles.borderLess}
              variant={"contained"}
              id="cancel-btn"
              onClick={handleCancel}
              color="primary"
              data-testid="cancel-button"
            >
              cancel
            </Button>
          </Grid>
        </>
      ) : null}
      {!showDialogBox ? (
        <Grid item xs={12}>
          <Grid item xs={12}>
            {formState.isSuccess ? (
              <Alert
                severity="success"
                id="success-alert"
                action={
                  <IconButton
                    id="close"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{formState.errMessage}</strong>
                {/* {SUCCESS} */}
              </Alert>
            ) : null}
            {formState.isUpdate ? (
              <Alert
                severity="success"
                id="success-alert2"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <strong>{formState.errMessage}</strong>
                {/* {SUCCESS} */}
              </Alert>
            ) : null}
            {formState.isFailed ? (
              <Alert
                severity="error"
                id="error-btn"
                action={
                  <IconButton
                    id="close"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleAlertClose}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {FAILED}
                {formState.errMessage}
              </Alert>
            ) : null}
          </Grid>
          <DialogBox
            open={openDialogBox}
            handleOk={confirmDelete}
            handleCancel={closeDialogBox}
            buttonOk={"Yes"}
            buttonCancel={"No"}
            classes={{
              root: styles.MainOfficeDialogRoot,
              container: styles.MainOfficeDialogboxContainer,
              paper: styles.MainOfficeDialogboxPaper,
              scrollPaper: styles.MainOfficeScrollPaper,
            }}
          >
            <div className={styles.DialogBoxTitle}>
              <Typography component="h1" variant="h1">
                Please Confirm
              </Typography>
            </div>
            <div className={styles.DialogBoxContext}>
              <p>{dialogBoxMsg}</p>
            </div>
          </DialogBox>
          <Paper className={styles.paper}>
            {ipLoading ? <SimpleBackdrop /> : null}
            <div className={styles.ScrollTable}>
              {newData.length !== 0 ? (
                <MaterialTable
                  columns={column}
                  data={newData}
                  pageSize={25}
                  onButtonClick={onRowClick}
                />
              ) : !ipLoading ? (
                <Typography component="h5" variant="h3">
                  There are no schedules defined.
                </Typography>
              ) : null}
            </div>
          </Paper>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

export default ProfileSettings;
