import React, { useEffect,useState } from "react";
import styles from "./Header.module.css";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Logout from "../../../../containers/Auth/Logout/Logout";
import * as routeConstant from "../../../../common/RouteConstants";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import { DialogBox } from "../../../../components/UI/DialogBox/DialogBox";
import { saveAs } from "file-saver";
import Alert from "../../../../components/UI/Alert/Alert";
import { sentry_error_catch } from "../../../../common/sentry_error_catch";
import { Typography } from "@mui/material";
import SimpleBackdrop from "../../../../components/UI/Layout/Backdrop/Backdrop";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import { FAILED } from "../../../../common/MessageConstants";
import { useErrorBoundary } from 'react-error-boundary';

export const Header: React.FC = () => {
  const history = useNavigate();
  const { resetBoundary, showBoundary } = useErrorBoundary();
  const [userNameState,setUserNameState] = useState("")
  // const user =  JSON.parse(localStorage.getItem("user") || "{}");
  const userdata = Cookies.getJSON("ob_user") || "{}";
  const [openSampleReportDialogBox,setOpenSampleReportDialogBox] = useState(false);
  const [openAboutDialogBox,setOpenAboutDialogBox] = useState(false);
  const [showDownloadBackdrop, setShowDownloadBackdrop] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    errMessage: "",
  });
  const user = typeof userdata === "object" ? userdata : JSON.parse(userdata);
  // const user = Cookies.getJSON("ob_user")
  //   ? Cookies.getJSON("ob_user") || ""
  //   : Logout();
  const ob_partnerData = Cookies.getJSON("ob_partnerData")
    ? Cookies.getJSON("ob_partnerData") || ""
    : null;
  let userRole: any;
  let username: any;

  const getHelpManual = () => {
    resetBoundary()
    // let ra_manual = process.env.PUBLIC_URL + "/user_manual/OB360_Manual.pdf";
    let ra_manual = process.env.REACT_APP_HELP_URL;
    window.open(ra_manual, "_blank");
  };
  const getSettings = () => {
    resetBoundary()
    history(routeConstant.SETTINGS);
    // let ra_manual = process.env.PUBLIC_URL + "/user_manual/OB360_Manual.pdf"
    // window.open(ra_manual, '_blank');
  };
  const getHome = () => {
    resetBoundary()
    try{
    if (user?.data?.getUserDetails?.edges[0]?.node?.isSuperuser) {
      history(routeConstant.ADMIN_DASHBOARD);
    } else {
      history(routeConstant.CLIENT);
      // localStorage.clear();
      localStorage.removeItem("name");
      localStorage.removeItem("customClientUrl");
      localStorage.removeItem("targetId");
      localStorage.removeItem("ipRange");
      localStorage.removeItem("ipAddress");
      localStorage.removeItem("re-runTargetName");
      localStorage.removeItem("userName");
      localStorage.removeItem("password");
      localStorage.removeItem("vpnUserName");
      localStorage.removeItem("vpnPassword");
      localStorage.removeItem("vpnFilePath");
      localStorage.removeItem("WinTargetName");
      localStorage.removeItem("LinuxTargetName");
      localStorage.removeItem("subnetMask");
      localStorage.removeItem("LinuxTargetId");
      localStorage.removeItem("winTargetId");
      localStorage.removeItem("updatetargettimestamp");
      localStorage.removeItem("WinIpAddress");
      localStorage.removeItem("TargetNameTimestamp");
      localStorage.removeItem("winUsername");
      localStorage.removeItem("winPassword");
      localStorage.removeItem("initialSubnetMask");
      localStorage.removeItem("initialTargetIpRange");
    }}catch(error){
      showBoundary(error)
    }
  };
  const getScanDetails = () => {
    resetBoundary()
    history(routeConstant.SCAN_DETAILS);
  }
  
  useEffect(() => {
    try {
      username = user.data.getUserDetails.edges[0].node.firstName + " " + user.data.getUserDetails.edges[0].node.lastName;
      setUserNameState(username)
    } catch (err:any) {
      if (user?.data?.getUserDetails?.edges[0]?.node?.username !== undefined) {
        username = user?.data?.getUserDetails?.edges[0]?.node?.username;
        setUserNameState(username)
      }else{
        Sentry.captureException(err)
        Logout();
      }
    }
  },[])

  const openSampleReportDialogBoxHandler =  () => {
    setOpenSampleReportDialogBox(true)
  }

  const openAboutDialogBoxHandler =  () => {
    history(routeConstant.ABOUTSECTION);
  }

  const downloadSampleReportHandler = (scanName:string) => {
    let DocUrl = '';
    let fileName = ''
    if(scanName === "pentest"){
      DocUrl = `${process.env.PUBLIC_URL}/sample_reports/Pentest/Reports.zip`;
      fileName = 'Sample PenTest Report'
    }else if(scanName === "advanced"){
      DocUrl = `${process.env.PUBLIC_URL}/sample_reports/Internal_Vulnerability/Reports.zip`;
      fileName = 'Sample Internal Vulnerability Report'
    }else if(scanName === "external"){
      DocUrl = `${process.env.PUBLIC_URL}/sample_reports/External_Vulnerability/Reports.zip`;
      fileName = 'Sample External Vulnerability Report'
    }
    setShowDownloadBackdrop(true)
    fetch(DocUrl, {
      method: "GET",
      cache: 'no-store'
    })
      .then((response: any) => {
        response.blob().then((blobData: any) => {
          saveAs(blobData,fileName);
          setShowDownloadBackdrop(false)
        });
      })
      .catch((err:any) => {
        sentry_error_catch(err,setShowDownloadBackdrop,setFormState)
      });
  }

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
  };
  
  return (
    <>
      <DialogBox
        open={openSampleReportDialogBox}
        handleOk={() => {
          setOpenSampleReportDialogBox(false);
        }}
        skipCancel={true}
        buttonOk={"Cancel"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeSampleReportDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        {showDownloadBackdrop ? <SimpleBackdrop /> : null}
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            {"Sample Reports"}
          </Typography>
        </div>
        <div style={{ margin: "8px 0px" }}>
          {(formState.isFailed) && (<Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {FAILED}
            <strong>{formState.errMessage}</strong>
          </Alert>)}
          <Grid container style={{ padding: "0px 30px" }}>
            <Grid item xs={10} md={10} style={{ padding: "10px 0px" }}>
              PenTest Report
            </Grid>
            <Grid item xs={2} md={2} style={{ padding: "10px 0px 0px 0px", textAlign: "center", cursor: "pointer" }}>
              <GetAppIcon onClick={() => {
                downloadSampleReportHandler("pentest")
              }} />
            </Grid>
          </Grid>
          <Grid container style={{ padding: "0px 30px" }}>
            <Grid item xs={10} md={10} style={{ padding: "10px 0px" }}>
              External Vulnerability Report
            </Grid>
            <Grid item xs={2} style={{ padding: "10px 0px 0px 0px", textAlign: "center", cursor: "pointer" }}>
              <GetAppIcon onClick={() => {
                downloadSampleReportHandler("external")
              }} />
            </Grid>
          </Grid>
          <Grid container style={{ padding: "0px 30px" }}>
            <Grid item xs={10} md={10} style={{ padding: "10px 0px" }}>
              Internal Vulnerability Report
            </Grid>
            <Grid item xs={2} style={{ padding: "10px 0px 0px 0px", textAlign: "center", cursor: "pointer" }}>
              <GetAppIcon onClick={() => {
                downloadSampleReportHandler("advanced")
              }} />
            </Grid>
          </Grid>
        </div>
      </DialogBox>
      <div className={styles.Header} data-testid="Header">
        <Card className={styles.root}>
          <Grid container>
            <Grid item xs={12} className={styles.RALogoImg}>
              <div>
                <img
                  id="get-home"
                  src={process.env.PUBLIC_URL + "/OB360.png"}
                  alt="user icon"
                  onClick={getHome}
                />
              </div>
            </Grid>
            <Grid item xs={12} className={styles.userInfo}>
              <Grid item xs={5}></Grid>
              <Grid item xs={10} style={{textAlign:"left"}}>
                <span className={styles.userNameLabel}>
                  <button onClick={getHome} style={{cursor:"pointer"}} className={styles["alert-url"]}>Home</button>
                </span>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                <span onClick={openAboutDialogBoxHandler} style={{cursor:"pointer"}} className={styles.userNameLabel}>About</span>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                <span onClick={openSampleReportDialogBoxHandler} style={{cursor:"pointer"}} className={styles.userNameLabel}>Sample Reports</span>
              </Grid>
              <Grid item xs={10} style={{textAlign:"right"}}>
                <span className={styles.userNameLabel}>
                  {userNameState}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
                {ob_partnerData ? (
                  <span className={styles.userNameLabel}>
                    <a
                      id="scan-details"
                      className={styles.logout}
                      onClick={getScanDetails}
                    >
                      Usage
                    </a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                  </span>
                ) : null}
                {ob_partnerData ? (
                  <span className={styles.userNameLabel}>
                    <a
                      id="settings"
                      className={styles.logout}
                      onClick={getSettings}
                    >
                      Settings
                    </a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                  </span>
                ) : null}
                <span className={styles.userNameLabel}>
                  <a id="help" className={styles.logout} onClick={getHelpManual}>
                    Help
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
                <span className={styles.userNameLabel}>
                  <a id="logout" className={styles.logout} onClick={Logout}>
                    Logout
                  </a>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default Header;
