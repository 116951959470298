export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const DASHBOARD = "/dashboard";
export const CLIENT = "/client";
export const CLIENT_FORM = "/client-form";
export const CLIENT_FORM_ADD = "/client-form/add";
export const CLIENT_FORM_EDIT = "/client-form/edit/";
export const HELP = "/help";
export const ABOUTSECTION = "/about-section"

//------------PEN-TEST ROUTES---------------//
export const PEN_TEST = "/pentest";
export const ADVANCED_PEN_TEST = "/advanced-pentest";

//------------RA ROUTES---------------//
export const TARGET = "/target";
export const VPN_CONNECT = "/vpn-connect";
export const LOGIN_CREDENTIALS = "/login-credential";
export const EXTERNAL_TARGET = "/external-target";
export const TASK_DETAILS = "/task-details";
export const LINUX_NETWORK = "/linux-network-details";
export const WINDOWS_NETWORK = "/windows-network-details";
export const REPORT_STATUS = "/report-status";
export const RA_REPORT_LISTING = "/report-listing";
//------------ADMIN ROUTES---------------//
export const ADMIN_DASHBOARD = "/admin-dashboard";
export const TEMPLATE = "/admin-template";
export const ADD_PARTNER = "/partners";
export const PARTNER_FORM = "/partner-form";
export const PARTNER_FORM_ADD = "/partner-form/add";
export const PARTNER_FORM_EDIT = "/partner-form/edit/";
export const PARTNER_USER = "/partner-user";
export const PARTNER_USER_FORM = "/partner-user-form";
export const PARTNER_USER_FORM_ADD = "/partner-user-form/add";
export const PARTNER_USER_FORM_EDIT = "/partner-user-form/edit/";
export const ADMIN_REPORT_STATUS = "/admin-report-status";
export const ADMIN_VPN_STATUS = "/vpn-status";
export const SETTINGS = "/settings";
export const SCAN_DETAILS = '/scan-details';
export const ADMIN_PARTNER = "/admin/delete-partner-list";
export const ADMIN_USER = "/admin/delete-user-list";
export const ADMIN_CLIENT = "/admin/delete-client-list";
export const ADMIN_TARGET = '/admin/target-list';
export const ADMIN_SUB_TARGET_DETAILS = '/admin/sub-target-details';
export const DELETE_TARGET = '/admin/delete-target-list';
//------------PROSPECT ROUTES---------------//

export const VIEW_PROSPECT = "/prospect";
